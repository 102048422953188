import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from "../../../../store/store";
import BrandsTable from "./BrandsTable";
import { editSection } from "../../../../store/Sections/SectionsSlice";
import { brand } from "../../../../types/brand";
import AddEditDialogBrands from "./AddEditDialogBrands";
import { useFetch, useFilter } from "../../../../ApiS";
import { setBrands } from "../../../../store/Brands/BrandsSlice";
import Pagination from "../../../Pagination";
import Loader from "../../../Loader";
import { section } from "../../../../types/section";

interface data {
  data: brand[];
  per_page: number;
  last_page: number;
}

function Brands() {
  const { sectionId } = useParams();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(search);
  const isSubsection = searchParams.get("is_subsection");
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<data>(
    `admin/sections/${sectionId}?page=${searchParams.get("page")}`,
    `brands${sectionId}?page=${searchParams.get("page")}`
  );
  const section = useFetch<{ data: section }>(
    `admin/sections/detail/${sectionId}`,
    `admin/sections/details/${sectionId}`
  );

  const brands = useSelector((state: RootState) => state.brands.value);

  useEffect(() => {
    if (data) dispatch(setBrands(data.data!));
  }, [data]);

  return isLoading || section.query.isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">
        {isSubsection ? "الأقسام الفرعية للماركة" : "ماركات القسم"} :{" "}
        {section.query.data?.data?.ar_name}
      </h2>
      <div className="overflow-x-auto">
        <BrandsTable brands={brands} />
        <Pagination pageCount={data?.last_page!} />
      </div>
      <div className="fixed bottom-8 left-8">
        <AddEditDialogBrands />
      </div>
    </div>
  );
}

export default Brands;
