// LoginPage.tsx
import React, { useEffect, useState } from "react";
import logo from "./Logo.svg";

import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Loader";

import { Bounce } from "react-toastify";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePOST } from "../../../ApiS";

const ForgetPassword: React.FC = () => {
  const notify = (message: string) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };
  const navigate = useNavigate();
  const { mutation, handleChangeInput, handleSubmit } = usePOST(
    {
      email: "",
    },
    () => navigate("/code"),

    () => notify("الايميل غير صحيح يرجى ادخال ايميل صحيح")
  );

  return (
    <div className="flex-col items-center justify-center h-screen bg-gray-100 mt-12 ">
      <ToastContainer />
      {mutation.isLoading ? <Loader /> : ""}
      <div className="bg-white shadow-md rounded-full px-8 pt-6 pb-8 mb-4 w-[50%] max-md:w-[97%]  mx-auto ">
        <img
          src={logo}
          alt=""
          className=" w-full h-[300px] max-md:h-[200px] "
        />
      </div>
      <h1 className="text-xl font-semibold text-center my-2">
        نسيت كلمة المرور
      </h1>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[55%] max-md:w-[97%]  mx-auto">
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
            email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Enter your username"
            name="email"
            onChange={handleChangeInput}
          />
        </div>
        <div>
          <button
            className="w-[30%]  bg-primary rounded-xl text-white font-bold p-2 "
            onClick={() => {
              handleSubmit("admin/send/verification-code");
            }}
          >
            التالي
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
