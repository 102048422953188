import React, { useEffect, useState } from "react";
import Video from "../../../Video";
import { fileURL, useFetch, usePOST } from "../../../../ApiS";
import { toast } from "sonner";

type FormDataType = {
  "about-video"?: string;
};

export default function VideoContainer({
  video,
}: {
  video: string | undefined;
}) {
  const initialData: FormDataType = {};
  const { handleSubmit, setFormData, mutation, formData } = usePOST(
    initialData,
    () => successAction(),
    () => {}
  );
  const [viewVideo, setViewVideo] = useState<string | undefined>(fileURL + video);

  const successAction = () => {
    toast.success("تم التعديل");
    toast.dismiss("loading");
  };

  useEffect(() => {
    if (mutation.isLoading) toast.loading(`يتم التعديل"}`, { id: "loading" });
  }, [mutation]);

  const submit = () => {
    handleSubmit("admin/infos/update");
  };

  return (
    <div className="flex flex-col justify-center text-center items-center space-y-8 md:space-y-12">
      <Video
        viewVideo={viewVideo}
        setViewVideo={setViewVideo}
        setFormData={setFormData}
        name="about-video"
      />
      {formData["about-video"] && (
        <button
          onClick={submit}
          className="bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
        >
          حفظ
        </button>
      )}
    </div>
  );
}
