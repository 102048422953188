import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { branch } from "../../types/branch";
import branchImage from "../../images/camera.png";
import sliderimage1 from "../../images/sliderImages/sliderImage1.png";
import sliderimage2 from "../../images/sliderImages/sliderImage2.png";
import sliderimage3 from "../../images/sliderImages/sliderImage3.png";

export interface BranchState {
  value: branch[];
}

const initialState: BranchState = {
  value: [],
};

const mainBranchesSlice = createSlice({
  name: "mainBranchesSlice",
  initialState,
  reducers: {
    addMainBranch: (state, action: PayloadAction<branch>) => {
      state.value = [...state.value, action.payload];
    },
    removeMainBranch: (state, action: PayloadAction<branch>) => {
      state.value = state.value.filter(
        (section) => section.id !== action.payload.id
      );
    },
    setMainBranches: (state, action: PayloadAction<branch[]>) => {
      state.value = action.payload;
    },
    editMainBranch: (state, action: PayloadAction<branch>) => {
      state.value = state.value.map((section) => {
        if (section.id === action.payload.id) {
          return action.payload;
        }
        return section;
      });
    },
  },
});

export const {
  addMainBranch,
  removeMainBranch,
  setMainBranches,
  editMainBranch,
} = mainBranchesSlice.actions;
export default mainBranchesSlice.reducer;
