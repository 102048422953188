import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Offer from "./offer";
import { offer } from "../../../types/offer";

function OffersTable({
  offers,
  isLoading,
}: {
  offers: offer[];
  isLoading: boolean;
}) {
  return (
    <div className="rounded-sm border border-stroke min-w-[600px] bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col">
        <div className="grid grid-cols-4 rounded-sm bg-amber-50 text-amber-500  ">
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base text-start">
              اسم العرض
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              وصف العرض
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الخصم
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              العمليات
            </h5>
          </div>
        </div>

        {offers?.length > 0 ? (
          offers?.map((offer, key) => (
            <Offer offer={offer} key={key} length={offers.length} />
          ))
        ) : (
          <div className="mt-12 mb-12">
            <p className="font-bold text-red-600"> لايوجد اي عروض لعرضها</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default OffersTable;
