import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store/store";
import JoditEditor from "jodit-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { toast } from "sonner";
import { addProduct, editProduct } from "../../../store/Products/ProductsSlice";
import { product } from "../../../types/products";
import { fileURL, useFetch, useFilter, usePOST } from "../../../ApiS";
import { section } from "../../../types/section";
import Loader from "../../Loader";
import AddImage from "../../AddImage";
import { AxiosError } from "axios";

export type FormDataType = {
  id?: number;
  image?: File;
  ar_name?: string;
  en_name?: string;
  ar_description?: string;
  price?: number;
  quantity?: number;
  en_description?: string;
  _method?: string;
  is_special?: 1 | 0;
};

type Errors = {
  image?: string[];
  ar_name?: string[];
  en_name?: string[];
  ar_description?: string[];
  en_description?: string[];
  is_special?: string[];
  price?: string[];
  quantity?: string[];
};

interface data {
  data: section[];
}

function AddEditProducts() {
  //take brandId from params to differentiate between adding from a specific brand or adding a product to a brand
  const { productId, brandId } = useParams();
  const enabled = productId ? true : false;
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<data>("admin/sections", "sections", brandId ? false : true);
  const { searchParams } = useFilter();
  const isSubsection = searchParams.get("is_subsection")
  const productData = useFetch<{data: product}>(`admin/products/${productId}`, `product${productId}`, enabled);
  const product = productData.query.data?.data
  const arEditorRef = useRef<any>(null);
  const enEditorRef = useRef<any>(null);
  const [errors, setErrors] = useState<Errors>({});
  const [brand, setBrand] = useState(brandId ? brandId : productData.query.data?.data?.brand.id);
  const [section, setSection] = useState<section | undefined>(
    data?.data?.find((s) => s.id === product?.brand.section.id)
  );
  const initialData: FormDataType = productId
    ? { _method: "put" }
    : { _method: "post", is_special: 0 };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    setFormData,
    handleSubmit,
    handleChangeInput,
    formData,
    mutation,
    viewFiles,
    viewfile,
  } = usePOST(
    initialData,
    (data) => onSuccess(data?.data?.data!),
    (error) => onError(error)
  );

  const onError = (error: AxiosError<{ errors: Errors; message: string }>) => {
    toast.dismiss("loading");
    toast.error(error.response?.data.message);
    setErrors(error.response?.data.errors || {});
  };

  const onSuccess = (product: product) => {
    navigate(-1);
    toast.dismiss("loading");
    if (productId) {
      toast.success("تم التعديل بنجاح");
      dispatch(editProduct(product));
    } else {
      toast.success("تم الإضافة بنجاح");
      dispatch(addProduct(product));
    }
    setErrors({})
  };

  const handleChangeSection = (id: string) => {
    setSection(data?.data?.find((s) => s.id.toString() === id)!);
  };

  const handleChangeBrand = (id: string) => {
    setBrand(id);
  };

  useEffect(() => {
    if (data && productData.query.data){
      setSection(data?.data?.find((s) => s.id === product?.brand.section.id));
      setBrand(brandId ? brandId : productData.query.data?.data?.brand.id)
    }
  }, [data, productData.query.data]);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData.ar_name]);

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم ${productId ? "التعديل" : "الإضافة"}`, {
        id: "loading",
      });
  }, [mutation]);

  const addAction = () => {
    handleSubmit(`admin/${brand}/products`);
  };

  const editAction = () => {
    handleSubmit(`admin/${brand}/products/${productId}`);
  };

  const validate = () => {
    const keysToCheck = Object.keys(formData).filter(
      (key) => key !== "_method"
    ) as (keyof FormDataType)[];

    let isValid;

    if (keysToCheck.length > 0) {
      isValid = productId
        ? keysToCheck.some((key) => !!formData[key])
        : keysToCheck.length === 8 && brand;
    } else isValid = false;

    return isValid;
  };

  const submit = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!productId) {
      if (validate()) addAction();
      else toast.error("قم بتعبئة جميع الحقول");
    } else {
      if (validate()) {
        editAction();
      } else toast.error("قم بالتعديل");
    }
  };

  useEffect(() => {
    if (arEditorRef.current) {
      arEditorRef.current.value = product ? product?.ar_description : "";
    }
    if (enEditorRef.current) {
      enEditorRef.current.value = product ? product?.ar_description : "";
    }
  }, []);

  return isLoading || productData.query.isLoading ? (
    <Loader />
  ) : isError || productData.query.isError ? <p>{error?.message || productData.query.error?.message}</p> : (
    <div className="h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-center text-2xl">
        {product ? "تعديل المنتج: " + product?.ar_name : "إضافة منتج"}
      </h2>
      {!brandId && !isSubsection && (
        <div className="flex flex-col justify-center gap-4 my-4">
          <div className="flex items-center gap-2">
            <p className="font-semibold">القسم</p>

            <Select
              defaultValue={product?.brand?.section?.id.toString()}
              onValueChange={(value) => handleChangeSection(value)}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="القسم" />
              </SelectTrigger>
              <SelectContent className="z-9999 bg-white ">
                {data?.data?.map((section) => (
                  <SelectItem
                    className="cursor-pointer hover:bg-slate-100 transition-colors"
                    value={section.id.toString()}
                  >
                    {section.ar_name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {section && (
            <div className="flex items-center gap-2">
              <p className="font-semibold">الماركة</p>
              <Select
                defaultValue={product?.brand.id.toString()}
                onValueChange={(value) => handleChangeBrand(value)}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="الماركة" />
                </SelectTrigger>
                <SelectContent className="z-9999 bg-white ">
                  {section.sub_sections?.map((brand) => (
                    <SelectItem
                      className="cursor-pointer hover:bg-slate-100 transition-colors"
                      value={brand.id.toString()}
                    >
                      {brand.ar_name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        </div>
      )}
      <form className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
        <label className="col-span-1 sm:col-span-2">
          <AddImage
            inputName="image"
            initialImage={product ? fileURL + product?.image : undefined}
            viewImage={viewfile}
            handleChangeInput={handleChangeInput}
          />
          {errors.image &&
            errors.image.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الاسم بالعربي:</p>
          <input
            defaultValue={product?.ar_name}
            name="ar_name"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="اكتب الاسم"
            onChange={(e) => handleChangeInput(e)}
          />
          {errors.ar_name &&
            errors.ar_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الاسم بالانكليزي:</p>
          <input
            defaultValue={product?.en_name}
            name="en_name"
            onChange={(e) => handleChangeInput(e)}
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            dir="ltr"
            placeholder="Type Name"
          />
          {errors.en_name &&
            errors.en_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">السعر :</p>
          <input
            name="price"
            defaultValue={product?.price}
            type="number"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="اكتب السعر"
            onChange={(e) => handleChangeInput(e)}
          />
          {errors.price &&
            errors.price.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الكمية :</p>
          <input
            name="quantity"
            defaultValue={product?.quantity}
            type="number"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="اكتب السعر"
            onChange={(e) => handleChangeInput(e)}
          />
          {errors.quantity &&
            errors.quantity.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="w-full space-y-2 col-span-1 sm:col-span-2">
          <p className="font-semibold">الوصف بالعربي:</p>
          <JoditEditor
            onChange={(newContent) =>
              setFormData({ ...formData, ar_description: newContent })
            }
            value={productId ? product?.ar_description! : ""}
          />
          {errors.ar_description &&
            errors.ar_description.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="w-full space-y-2 col-span-1 sm:col-span-2">
          <p className="font-semibold">الوصف بالانكليزي:</p>
          <JoditEditor
            onChange={(newContent) =>
              setFormData({ ...formData, en_description: newContent })
            }
            value={productId ? product?.en_description! : ""}
          />
          {errors.en_description &&
            errors.en_description.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="flex items-center justify-center col-span-1 sm:col-span-2 gap-4 ">
          <p className="font-semibold"> منتج مميز:</p>
          <input
            type="checkbox"
            name="is_special"
            className="relative w-5 h-5"
            defaultChecked={product?.is_special === 1 ? true : false}
            onChange={(e) =>
              setFormData({
                ...formData,
                is_special: e.currentTarget.checked ? 1 : 0,
              })
            }
          />
          {errors.is_special &&
            errors.is_special.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <input
          onClick={(e) => submit(e)}
          type="submit"
          value={productId ? "تعديل" : "حفظ"}
          className="col-span-1 sm:col-span-2 bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
        />
      </form>
    </div>
  );
}

export default AddEditProducts;
