import React from "react";
import Description from "./Description";
import VideoContainer from "./VideoContainer";
import { useFetch } from "../../../../ApiS";
import Loader from "../../../Loader";

type dataType = {
  about: {
    video: string;
    description: { ar: string; en: string };
  };
};

export default function AboutUs() {
  const { query: {data, isLoading, isError, error} } = useFetch<dataType>(
    "admin/infos",
    "infos"
  );

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="space-y-8">
      <VideoContainer video={data?.about.video!} />
      <Description description={data?.about.description!} />
    </div>
  );
}
