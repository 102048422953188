import React, { useEffect, useState } from "react";
import { useFetch, usePOST } from "../../../ApiS";
import ImagesSlider from "./ImagesSlider";
import Loader from "../../Loader";

export type sliderImage = { file: string; id: number };

type sliders = {
  data: {
    home: sliderImage[];
    about: sliderImage[];
  };
};

export default function Sliders() {
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<sliders>("admin/infos/image-slider", "slider images");
  const [homeImages, setHomeImages] = useState<sliderImage[]>(data?.data.about!);
  const [aboutImages, setAboutImages] = useState<sliderImage[]>(data?.data.home!);
  const [type, setType] = useState("home");

  useEffect(() => {
    if(data) {
      setHomeImages(data?.data.home!)
      setAboutImages(data?.data.about!)
    }
  },[data])

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">صور السلايدر</h2>
      <div className="flex justify-center gap-12">
        <button
          onClick={() => setType("home")}
          className={`p-3 rounded-lg transition-colors hover:bg-amber-100 hover:text-amber-500 ${
            type === "home"
              ? "bg-amber-100 text-amber-500"
              : "bg-amber-500 text-white"
          }`}
        >
          الصفحة الرئيسية
        </button>
        <button
          onClick={() => setType("about")}
          className={`p-3 rounded-lg transition-colors hover:bg-amber-100 hover:text-amber-500 ${
            type === "about"
              ? "bg-amber-100 text-amber-500"
              : "bg-amber-500 text-white"
          }`}
        >
          معلومات عنا
        </button>
      </div>
      {type && (
        <ImagesSlider
          imageslider={type === "home" ? homeImages : aboutImages}
          setImageslider={type === "home" ? setHomeImages : setAboutImages}
          type={type}
        />
      )}
    </div>
  );
}
