import React from 'react'
import { contactMessage } from '../../../types/contactMessage';
import ContactMessage from './ContactMessage';

export default function ContactTable({
    messages,
  }: {
    messages: contactMessage[];
  }) {
    return (
      <div className="rounded-sm border border-stroke min-w-[700px] bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default dark:border-strokedark dark:bg-boxdark ">
        <div className="flex flex-col">
          <div className="grid grid-cols-5 rounded-sm bg-amber-50 text-amber-500 ">
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                الرقم
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                الاسم
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                الرسالة
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                الايميل
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                العمليات
              </h5>
            </div>
          </div>
  
          {messages?.length > 0 ? (
            messages?.map((message, key) => (
              <ContactMessage
                message={message}
                key={key}
                length={messages.length}
              />
            ))
          ) : (
            <div className="mt-12 mb-12">
              <p className="font-bold text-red-600"> لايوجد اي رسائل لعرضها</p>
            </div>
          )}
        </div>
      </div>
    );
}
