import { configureStore } from "@reduxjs/toolkit";
import sectionReduser from "./Sections/SectionsSlice";
import BranchesSlice from "./Branches/BranchesSlice";
import OffersSlice from "./Offers/OffersSlice";
import ProductsSlice from "./Products/ProductsSlice";
import UsersSlice from "./Users/UsersSlice";
import MainBranchesSlice from "./Branches/MainBranchesSlice";
import MainSectionsSlice from "./Sections/MainSectionsSlice";
import MainProductsSlice from "./Products/MainProductsSlice";
import ChatSlice from "./Chats/ChatSlice";
import BrandsSlice from "./Brands/BrandsSlice";
import AdminsSlice from "./Admins/AdminsSlice";
import ContactSlice from "./ContactMesssage/ContactSlice";
import MessagesSlice from "./Chats/MessagesSlice";
import OrdersSlice from "./Orders/OrdersSlice";

export const store = configureStore({
  reducer: {
    sections: sectionReduser,
    mainSections: MainSectionsSlice,
    branches: BranchesSlice,
    mainBranches: MainBranchesSlice,
    offers: OffersSlice,
    products: ProductsSlice,
    mainProducts: MainProductsSlice,
    users: UsersSlice,
    chat: ChatSlice,
    brands: BrandsSlice,
    admins: AdminsSlice,
    contactMessages: ContactSlice,
    messages: MessagesSlice,
    orders: OrdersSlice
  },
});

// Define RootState type
export type RootState = ReturnType<typeof store.getState>;

// Export the store type for use with dispatch
export type AppDispatch = typeof store.dispatch;
