import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { section } from "../../types/section";
import SectionImage from "../../images/section.png";
import BrandImage from "../../images/brand.png";

export interface SectionState {
  value: section[];
}

const initialState: SectionState = {
  value: [],
};

const mainSectionSlice = createSlice({
  name: "mainSectionSlice",
  initialState,
  reducers: {
    addMainSection: (state, action: PayloadAction<section>) => {
      state.value = [...state.value, action.payload];
    },
    removeMainSection: (state, action: PayloadAction<section>) => {
      state.value = state.value.filter(
        (section) => section.id !== action.payload.id
      );
    },
    setMainSections: (state, action: PayloadAction<section[]>) => {
      state.value = action.payload;
    },
    editMainSection: (state, action: PayloadAction<section>) => {
      state.value = state.value.map((section) => {
        if (section.id === action.payload.id) {
          return action.payload;
        }
        return section;
      });
    },
  },
});

export const {
  addMainSection,
  removeMainSection,
  setMainSections,
  editMainSection,
} = mainSectionSlice.actions;
export default mainSectionSlice.reducer;
