import React, { useEffect } from "react";
import BranchesTable from "./BranchesTable";
import AddButton from "../../ui/AddButton";
import { Link, useLocation } from "react-router-dom";
import { useFetch } from "../../../ApiS";
import { branch } from "../../../types/branch";
import { setBranches } from "../../../store/Branches/BranchesSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Loader from "../../Loader";
import Pagination from "../../Pagination";

interface data {
  data: {
    data: branch[];
    per_page: number;
    last_page: number;
  };
}

function Branches() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { query: {data, isLoading, isError, error} } = useFetch<data>(
    `admin/branches?page=${searchParams.get("page")}`,
    `branches?page=${searchParams.get("page")}`
  );
  const branches = useSelector((state: RootState) => state.branches.value)
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(setBranches(data?.data?.data!));
    }
  }, [data]);
  return isLoading ? (
    <Loader />
  ) : isError ? <p>{"خطأ في جلب البيانات"}</p> :  (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">الفروع</h2>
      <div className="overflow-x-auto">
        <BranchesTable branches={branches} />
      </div>
      <Pagination pageCount={data?.data?.last_page} />
      <Link to="/branches/add" className="fixed bottom-8 left-8">
        <AddButton />
      </Link>
    </div>
  );
}

export default Branches;
