import AddButton from "../../ui/AddButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/Dialog";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import EditButton from "../../ui/EditButton";
import { employee } from "../../../types/branch";
import { FormDataType } from "./AddEditBranch";
import { fileURL } from "../../../ApiS";

type EmployeeFormDataType = {
  id?: number;
  ar_name?: string;
  en_name?: string;
  ar_job?: string;
  en_job?: string;
  image?: File | string;
  uploaded?: boolean;
};

export default function DialogAddEmployee({
  setEmployees,
}: {
  setEmployees: React.Dispatch<React.SetStateAction<employee[] | undefined>>;
}) {
  const inputRef = useRef<any>(null);
  const [viewImage, setViewImage] = useState("");
  const [newEmployee, setNewEmployee] = useState<EmployeeFormDataType>({});

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setViewImage(imageUrl);
      setNewEmployee((prev) => ({ ...prev, image: file }));
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewEmployee((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    const keysToCheck = Object.keys(
      newEmployee
    ) as (keyof EmployeeFormDataType)[];

    let isValid;

    if (keysToCheck.length > 0) {
      isValid = keysToCheck.length === 5;
    } else isValid = false;

    return isValid;
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      setEmployees((prev) => [
        ...prev!,
        { ...newEmployee, uploaded: true } as employee,
      ]);
      document.getElementById("close-trigger")?.click();
      setViewImage("");
      setNewEmployee({});
    } else toast.error("قم بتعبئة جميع الحقول");
  };

  return (
    <Dialog>
      <DialogTrigger>
        {" "}
        <AddButton />
      </DialogTrigger>
      <DialogContent
        dir="rtl"
        className="bg-white max-h-screen overflow-y-auto"
      >
        <DialogHeader>
          <DialogTitle className="w-fit">إضافة موظف</DialogTitle>
        </DialogHeader>
        <form
          onSubmit={(e) => submit(e)}
          className="flex flex-col gap-6 justify-center items-center"
        >
          <input
            type="file"
            hidden
            ref={inputRef}
            accept="image/*"
            onChange={(e) => handleAddImage(e)}
          />
          {viewImage ? (
            <div className="relative">
              <img
                src={viewImage}
                alt={"صورة الموظف"}
                className="h-40 rounded-md "
              />
              <div
                onClick={(e) => {
                  inputRef?.current.click();
                  e.preventDefault();
                }}
                className="absolute left-2 top-2"
              >
                <EditButton />
              </div>
            </div>
          ) : (
            <button
              onClick={(e) => {
                inputRef?.current.click();
                e.preventDefault();
              }}
              className={`${
                viewImage && "hidden"
              } rounded-lg border border-amber-500 bg-white text-amber-500 transition-colors hover:bg-white hover:text-amber-500 p-2`}
            >
              قم بتحميل صورة من جهازك
            </button>
          )}
          <label className="w-full space-y-2">
            <p>الاسم بالعربي:</p>
            <input
              type="text"
              name="ar_name"
              className="p-2 w-full border border-gray-200 rounded-md"
              placeholder="اكتب الأسم"
              onChange={(e) => handleChangeInput(e)}
            />
          </label>
          <label className="w-full space-y-2">
            <p>الاسم بالانكليزي:</p>
            <input
              type="text"
              name="en_name"
              className="p-2 w-full border border-gray-200 rounded-md"
              placeholder="Type Name"
              dir="ltr"
              onChange={(e) => handleChangeInput(e)}
            />
          </label>
          <label className="w-full space-y-2">
            <p>الوظيفة بالعربي:</p>
            <input
              type="text"
              name="ar_job"
              className="p-2 w-full border border-gray-200 rounded-md"
              placeholder="اكتب الوظيفة"
              onChange={(e) => handleChangeInput(e)}
            />
          </label>
          <label className="w-full space-y-2">
            <p>الوظيفة بالانكليزي:</p>
            <input
              type="text"
              name="en_job"
              className="p-2 w-full border border-gray-200 rounded-md"
              placeholder="jop Name"
              dir="ltr"
              onChange={(e) => handleChangeInput(e)}
            />
          </label>
          <button
            className="bg-amber-500 text-white rounded-md w-full border border-amber-500 transition-colors p-2 hover:bg-white hover:text-amber-500"
            type="submit"
          >
            إضافة
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
