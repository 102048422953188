import React from "react";
import { Pencil } from "./svgs";

function EditButton() {
  return (
    <button
      className="bg-green-500 text-white border cursor-pointer border-green-500 hover:bg-white hover:text-green-500 p-1 rounded"
    >
      <Pencil />
    </button>
  );
}

export default EditButton;
