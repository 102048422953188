import { useEffect, useRef, useState } from "react";
import DeleteButton from "../../ui/DeleteButton";
import EditButton from "../../ui/EditButton";
import { sliderImage } from "./Sliders";
import { fileURL, useDelete, usePOST } from "../../../ApiS";
import { toast } from "sonner";
import DeleteDialog from "../../DeleteDialog";
import EditDialog from "../../DeleteDialog";

export default function SliderImage({
  image,
  setImageslider,
}: {
  image: sliderImage;
  setImageslider: React.Dispatch<React.SetStateAction<sliderImage[]>>;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    handleSubmit,
    setFormData,
    formData,
    mutation: { isLoading },
  } = usePOST(
    {},
    (data) => onUpdataSuccess(data?.data?.data),
    (error) => toast.error("فشل في الإضافة")
  );
  const inputRef = useRef<any>(null);
  const { mutation, deleteItem } = useDelete(
    () => onDeleteSuccess(),
    () => toast.error("فشل الحذف")
  );

  const onUpdataSuccess = (data: sliderImage) => {
    toast.dismiss("loading");
    toast.success("تم تعديل الصورة");
    setImageslider((prev) =>
      prev.map((im) => {
        if (im.id === image.id) return data;
        else return im;
      })
    );
  };

  useEffect(() => {
    if (mutation.isLoading) toast.loading("يتم الحذف", {id: "loading"});
  }, [mutation]);

  useEffect(() => {
    if (isLoading) toast.loading("يتم التعديل", {id: "loading"});
  }, [isLoading]);

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      handleSubmit(`admin/infos/image-slider/${image.id}`);
    }
  }, [formData]);

  const onDeleteSuccess = () => {
    toast.dismiss("loading");
    toast.success("تمت إزالة الصورة");
    setImageslider((prev) => prev.filter((im) => im.id !== image.id));
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = () => {
    deleteItem(`admin/infos/image-slider/${image.id}`);
  };

  const handleEditImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData({ file, _method: "PUT" });
    }
  };

  return (
    <div className="relative">
      {isModalOpen && (
        <div className="fixed inset-0 z-99999 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full md:w-1/2">
            <img
              alt="slider image"
              src={fileURL + image.file}
              onClick={handleCloseModal}
              className="cursor-pointer"
            />
          </div>
        </div>
      )}
      <img
        src={fileURL + image.file}
        onClick={handleImageClick}
        className="cursor-pointer"
      />

      <div className="absolute left-2 top-2 flex gap-2">
        <DeleteDialog action={handleDelete} title="هل تريد حذف الصورة؟" />
        {/* <EditDialog isEdit={true} action={handleEdit} title="هل تريد تعديل الصورة؟" /> */}
        <div onClick={() => inputRef.current.click()}>
          <EditButton />
        </div>
      </div>

      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleEditImage(e)}
        ref={inputRef}
        className="hidden"
      />
    </div>
  );
}
