import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { branch } from "../../types/branch";
import branchImage from "../../images/camera.png";
import sliderimage1 from "../../images/sliderImages/sliderImage1.png";
import sliderimage2 from "../../images/sliderImages/sliderImage2.png";
import sliderimage3 from "../../images/sliderImages/sliderImage3.png";

export interface BranchState {
  value: branch[];
}

const initialState: BranchState = {
  value: [],
};

const branchSlice = createSlice({
  name: "branchesSlice",
  initialState,
  reducers: {
    addBranch: (state, action: PayloadAction<branch>) => {
      state.value = [...state.value, action.payload];
    },
    removeBranch: (state, action: PayloadAction<branch>) => {
      state.value = state.value.filter(
        (section) => section.id !== action.payload.id
      );
    },
    setBranches: (state, action: PayloadAction<branch[]>) => {
      state.value = action.payload;
    },
    editBranch: (state, action: PayloadAction<branch>) => {
      state.value = state.value.map((section) => {
        if (section.id === action.payload.id) {
          return action.payload;
        }
        return section;
      });
    },
  },
});

export const { addBranch, removeBranch, setBranches, editBranch } =
  branchSlice.actions;
export default branchSlice.reducer;
