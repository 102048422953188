import { Key, useEffect, useState } from "react";
import { section } from "../../../types/section";
import { useDispatch } from "react-redux";
import {
  editSection,
  removeSection,
} from "../../../store/Sections/SectionsSlice";
import { Link, useLocation } from "react-router-dom";
import EditDialog from "./AddEditDialog";
import DeleteDialog from "../../DeleteDialog";
import {
  addMainSection,
  editMainSection,
  removeMainSection,
} from "../../../store/Sections/MainSectionsSlice";
import { fileURL, useDelete, usePOST } from "../../../ApiS";
import { toast } from "sonner";
import { Check, X } from "lucide-react";

function Section({
  section,
  key,
  length,
}: {
  section: section;
  key: Key;
  length: number;
}) {
  const dispatch = useDispatch();

  const {
    deleteItem,
    mutation: { isLoading },
  } = useDelete(
    () => onDelete(),
    () => {
      toast.error("Error while deleting.");
      toast.dismiss("loading");
    }
  );

  const { handleSubmit, mutation } = usePOST(
    { _method: "put", is_special: 0 },
    () => successPost(),
    () => {
      toast.error("Error while deleting.");
      toast.dismiss("loading");
    }
  );

  const successPost = () => {
    toast.dismiss("loading");
    toast.success("تمت الإزالة");
    dispatch(removeMainSection(section));
  };

  useEffect(() => {
    if (mutation.isLoading || isLoading)
      toast.loading(`يتم الإزالة`, { id: "loading" });
  }, [mutation, isLoading]);

  const onDelete = () => {
    toast.dismiss("loading");
    toast.success("تمت الإزالة");
    dispatch(removeSection(section));
  };

  const deleteAction = () => {
    if (pathname.includes("homePage/mainSections"))
      handleSubmit(`admin/sections/${section.id}`);
    else {
      deleteItem(`admin/sections/${section.id}`);
    }
  };

  const { pathname } = useLocation();

  return (
    <div
      className={`grid grid-cols-4 ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark"
      }`}
      key={key}
    >
      <div className="flex items-center gap-3 p-2.5 xl:p-5">
        <div className="flex-shrink-0">
          <img
            src={`${fileURL}${section.image}`}
            alt="section image"
            className="w-20 rounded"
          />
        </div>
        <p className="text-black dark:text-white md:block">{section.ar_name}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {section.is_special === 1 ? (
            <Check className="w-5 text-blue-800" />
          ) : (
            <X className="w-5 text-blue-800" />
          )}
        </p>
      </div>

      <div className={`items-center justify-center p-2.5 flex xl:p-5`}>
        <Link
          to={`/sections/${section.id}/brands/?page=1`}
          className="bg-white text-amber-500 p-1 border border-amber-500 rounded text-center flex items-center justify-center"
        >
          عرض
        </Link>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-3 p-2.5 xl:p-5">
        <EditDialog id={section.id} />
        <DeleteDialog action={deleteAction} title={"هل تريد حذف القسم؟"} />
      </div>
    </div>
  );
}

export default Section;
