import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { product } from "../../types/products";

export interface ProductState {
  value: product[];
}
const initialState: ProductState = {
  value: [

  ],
};
const mainProductsSlice = createSlice({
  name: "mainProductsSlice",
  initialState,
  reducers: {
    addMainProduct: (state, action: PayloadAction<product>) => {
      state.value = [...state.value, action.payload];
    },
    removeMainProduct: (state, action: PayloadAction<product>) => {
      state.value = state.value.filter(
        (product) => product.id !== action.payload.id
      );
    },
    setMainProduct: (state, action: PayloadAction<product[]>) => {
      state.value = action.payload;
    },
    editMainProduct: (state, action: PayloadAction<product>) => {
      state.value = state.value.map((product) => {
        if (product.id === action.payload.id) {
          return action.payload;
        }
        return product;
      });
    },
  },
});

export const { addMainProduct, removeMainProduct, setMainProduct, editMainProduct } =
mainProductsSlice.actions;
export default mainProductsSlice.reducer;
