import { Trash } from "./svgs";

function DeleteButton() {
  return (
    <button className="p-1 border border-red-500 bg-red-500 text-white rounded cursor-pointer hover:bg-white hover:text-red-500 transition-colors">
      <Trash />
    </button>
  );
}

export default DeleteButton;
