import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { useFetch, usePOST } from "../../../../ApiS";
import Loader from "../../../Loader";
import { toast } from "sonner";

type FormDataType = {
  "about-description-en"?: string;
  "about-description-ar"?: string
};

function Description({description} : {description: { ar: string; en: string };}) {
  const initialData: FormDataType = {};
  const arEditorRef = useRef<any>(null);
  const enEditorRef = useRef<any>(null);
  const { handleSubmit, setFormData, formData, mutation } = usePOST(
    initialData,
    () => successAction(),
    (error) => errorAction(error)
  );

  const successAction = () => {
    toast.success("تم التعديل");
    toast.dismiss("loading")
  };

  const errorAction = (error: Error) => {
    toast.error(error.message)
    toast.dismiss("loading");
  }

  useEffect(() => {
    if (mutation.isLoading) toast.loading(`يتم التعديل"}`, {id: "loading"});
  }, [mutation.isLoading]);

  useEffect(() => {
    setFormData(initialData);
  }, []);

  useEffect(() => {
    if (arEditorRef.current) {
      arEditorRef.current.value = description ? description?.ar : "";
    }
    if (enEditorRef.current) {
      enEditorRef.current.value =  description ? description?.en : "";
    }
  }, []);


  const submit = () => {
    handleSubmit("admin/infos/update")
  };

  return (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">الوصف</h2>
      <div className="space-y-4">
        <h4 className="text-lg text-start">الوصف بالعربي</h4>
        <JoditEditor
          ref={arEditorRef}
          value={description?.ar!}
          onChange={(value) =>
            setFormData({ ...formData, "about-description-ar": value })
          }
        />
      </div>
      <div className="space-y-4">
        <h4 className="text-lg text-start">الوصف بالانكليزي</h4>
        <JoditEditor
          ref={enEditorRef}
          value={description?.en!}
          onChange={(value) =>
            setFormData({...formData, "about-description-ar": value})
          }
        />
      </div>
      {Object.entries(formData).length > 0 && (
        <button
          onClick={submit}
          className="bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
        >
          حفظ
        </button>
      )}
    </div>
  );
}

export default Description;
