import React, { useRef, useState } from "react";

import Image from "./Image";
import AddButton from "./ui/AddButton";
import { FilesState, fileURL } from "../ApiS";
import { image } from "./pages/Branches/AddEditBranch";

function ImagesSlider({
  viewImages,
  setViewImages,
  handleChangeArrayFiles,
  setImages,
}: {
  viewImages: image[] | undefined;
  setImages: React.Dispatch<React.SetStateAction<FilesState>>;
  handleChangeArrayFiles: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setViewImages: React.Dispatch<React.SetStateAction<image[] | undefined>>;
}) {
  const inputRef = useRef<any>(null);

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setViewImages((prev) =>
        prev
          ? [
              ...prev!,
              {
                url: imageUrl,
                uploaded: true,
                file: file,
              },
            ]
          : [{ url: imageUrl, uploaded: true, file: file }]
      );
    }
  };

  return (
    <div className="text-center relative space-y-8 md:space-y-12">
      <div
        onClick={(e) => e.stopPropagation()}
        className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-8"
      >
        {viewImages?.map(
          (image: image, index) =>
            !image.deleted && (
              <Image
                index={index}
                setViewImages={setViewImages}
                key={index}
                image={image}
                setImages={setImages}
              />
            )
        )}
      </div>

      <div className="w-fit mx-auto" onClick={() => inputRef.current?.click()}>
        <AddButton />
      </div>

      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleAddImage(e)}
        ref={inputRef}
        className="hidden"
      />
    </div>
  );
}

export default ImagesSlider;
