import React from "react";
import { Pencil } from "./svgs";
import { Eye } from "lucide-react";

function ShowButton() {
  return (
    <button
      className="bg-blue-500 text-white border border-blue-500 hover:bg-white hover:text-blue-500 p-1 rounded"
    >
      <Eye className="w-4.5 h-4.5" />
    </button>
  );
}

export default ShowButton;
