import React, { useEffect } from "react";
import { fileURL, useFetch, usePOST } from "../../../ApiS";
import { toast } from "sonner";
import Loader from "../../Loader";
import AddImage from "../../AddImage";
import { AxiosError } from "axios";

type FormDataType = {
  "home-search-ar"?: string;
  "home-search-en"?: string;
  "logo-image"?: string;
};

interface dataType {
  home: { search: { ar: string; en: string } };
  logo: { image: string };
}

export default function Infos() {
  const { query: {data, isLoading, isError, error} } = useFetch<dataType>(
    "admin/infos",
    "infos"
  );
  const initialData: FormDataType = {};
  const { handleSubmit, handleChangeInput, handleChangeTextArea, formData, mutation, setFormData, viewfile } =
    usePOST(
      initialData,
      () => onSuccess(),
      (error) => onError(error)
    );

  useEffect(() => {
    if (mutation.isLoading) toast.loading(`يتم التعديل`, { id: "loading" });
  }, [mutation.isLoading]);

  const onSuccess = () => {
    toast.success("تم التعديل");
    toast.dismiss("loading");
    setFormData({});
  };

  const onError = (error: AxiosError<{errors: string[], message: string}>) => {
    toast.error(error.response?.data.errors.at(0));
    toast.dismiss("loading");
  };

  const submit = () => {
    handleSubmit("admin/infos/update");
  };

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">المعلومات</h2>
      <form className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <label className="w-full space-y-2">
          <p className="font-semibold">عنوان البحث بالعربي:</p>
          <textarea
            defaultValue={data?.home?.search?.ar}
            name="contact_us-headline-ar"
            className="p-2 h-32 w-full border border-slate-300 rounded-md"
            placeholder="عنوان البحث بالعربي"
            onChange={handleChangeTextArea}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">عنوان البحث بالانكليزي:</p>
          <textarea
            defaultValue={data?.home?.search?.en}
            name="contact_us-headline-ar"
            className="p-2 h-32 w-full border border-slate-300 rounded-md"
            placeholder="عنوان البحث بالانكليزي"
            onChange={handleChangeTextArea}
          />
        </label>
        <label className="w-full space-y-2 col-span-1 md:col-span-2">
          <p className="font-semibold">اللوغو:</p>
          <AddImage inputName="logo-image" initialImage={fileURL + data?.logo?.image } viewImage={viewfile} handleChangeInput={handleChangeInput} />
        </label>
      </form>
      {Object.entries(formData).length > 0 && (
        <button
          onClick={submit}
          className="bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
        >
          حفظ
        </button>
      )}
    </div>
  );
}
