import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "../../types/chat";

export interface MessageState {
  value: message[];
}

const initialState: MessageState = {
  value: [],
};

const messageSlice = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<message[]>) => {
      state.value = action.payload;
    },
    addMessage: (state, action: PayloadAction<message>) => {
      state.value = [action.payload, ...state.value];
    },
    replaceMessage: (state, action: PayloadAction<message>) => {
      state.value = state.value.map((msg) => {
        if (msg.loading && msg.content === action.payload.content) {
          return action.payload;
        }
        return msg;
      });
    },
    removeMessage: (state, action: PayloadAction<message>) => {
      state.value = state.value.filter(
        (section) => section.id !== action.payload.id
      );
    },
  },
});

export const { setMessages, addMessage, removeMessage, replaceMessage } =
  messageSlice.actions;
export default messageSlice.reducer;
