import React from "react";

export default function AddButton() {
  return (
    <button className="p-2 w-fit rounded-full transition-colors bg-amber-500 text-white hover:bg-amber-100 hover:text-amber-500">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-plus"
      >
        <path d="M5 12h14" />
        <path d="M12 5v14" />
      </svg>
    </button>
  );
}
