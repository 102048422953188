import { message } from "../../../types/chat";
import { Check, CheckCheck, Loader } from "lucide-react";

function Message({
  message,
  isSameUser,
  username,
}: {
  message: message;
  isSameUser: boolean;
  username: string;
}) {
  return (
    <div
      className={`w-full flex ${
        !message.sender_id ? "justify-start" : "justify-end"
      }`}
    >
      <div className={`flex relative items-start gap-2 `}>
        <div className={`flex-1 flex flex-col gap-2 justify-start items-start`}>
          <p className={`${isSameUser && "hidden"} ${message.sender_id && "self-end"} font-semibold text-sm`}>
            {message.sender_id ? username : "Admin"}
          </p>
          <div
            className={`rounded-xl min-w-48 relative w-full  p-4 ${
              !message.sender_id ? "bg-amber-200 rounded-tr-none" : "bg-slate-200 rounded-tl-none"
            }`}
          >
            <p className="text-sm font-medium">{message.content}</p>
          </div>
        </div>
        {!message.sender_id && (
          <div className={`absolute bottom-2 left-2 w-8`}>
            {message.loading ? (
              <Loader className="w-4 animate-spin text-blue-700" />
            ) : message.read_at ? (
              <CheckCheck className="w-4 text-blue-700" />
            ) : (
              <Check className="w-4 text-blue-700" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Message;
