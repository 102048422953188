import React, { useEffect } from "react";
import { useFetch } from "../../../ApiS";
import { contactMessage } from "../../../types/contactMessage";
import ContactTable from "./ContactTable";
import Loader from "../../Loader";
import { useLocation } from "react-router-dom";
import Pagination from "../../Pagination";
import { useDispatch, useSelector } from "react-redux";
import { setMessages } from "../../../store/ContactMesssage/ContactSlice";
import { RootState } from "../../../store/store";

type dataType = {
  data: contactMessage[];
  per_page: number;
  last_page: number;
};

function ContactUs() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const dispatch = useDispatch();
  const { query: {data, isLoading, isError, error, refetch} } = useFetch<dataType>(
    `admin/contact-messages?page=${searchParams.get("page")}`,
    `contact-messages?page=${searchParams.get("page")}`,
  );
  const messages = useSelector((state: RootState ) => state.contactMessages.value);
  useEffect(() => {
    if(data) {
      dispatch(setMessages(data?.data))
    }
  }, [data])

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">تواصل معنا</h2>
      <div className="overflow-x-auto">
        <ContactTable messages={messages} />
      </div>
      <Pagination pageCount={data?.last_page!} />
    </div>
  );
}

export default ContactUs;
