import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import AddButton from "../../ui/AddButton";
import EditButton from "../../ui/EditButton";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/Dialog";
import { fileURL, useFetch, usePOST } from "../../../ApiS";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { addSection, editSection } from "../../../store/Sections/SectionsSlice";
import { section } from "../../../types/section";
import { AxiosError } from "axios";
import AddImage from "../../AddImage";

type FormDataType = {
  id?: number;
  image?: File | string;
  ar_name?: string;
  en_name?: string;
  is_special?: 1 | 0;
  _method?: string;
};

type Errors = {
  image?: string[];
  ar_name?: string[];
  en_name?: string[];
  is_special?: string[];
}

const soso = {
  id: 1,
  image: "",
  ar_name: "",
  en_name: "",
  is_special: 1,
};

export default function AddEditDialog({ id }: { id?: number }) {
  const sections = useSelector((state: RootState) => state.sections.value);
  const section = sections?.find((s) => s.id! === id);
  const [errors, setErrors] = useState<Errors>({})
  const initialData: FormDataType = id
    ? { _method: "PUT" }
    : { _method: "POST", is_special: 0 };
  const dispatch = useDispatch();

  const {
    setFormData,
    handleSubmit,
    handleChangeInput,
    formData,
    mutation,
    viewfile,
  } = usePOST(
    initialData,
    (data) => onSuccess(data?.data?.data),
    (error) => onError(error)
  );

  const onError = (error: AxiosError<{ errors: Errors; message: string }>) => {
    toast.dismiss("loading");
    toast.error(error.response?.data.message);
    setErrors(error.response?.data.errors || {})
  };

  const onSuccess = (data: section) => {
    document.getElementById("close-trigger")?.click();
    if (id) {
      dispatch(editSection(data));
    } else {
      dispatch(addSection(data));
    }
    toast.dismiss("loading");
    toast.success(`تم ${id ? `التعديل` : `الإضافة`}`);
    setErrors({})
  };

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم ${id ? "التعديل" : "الإضافة"}`, { id: "loading" });
  }, [mutation]);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData.ar_name]);

  const addAction = () => {
    handleSubmit("admin/sections");
  };

  const editAction = () => {
    handleSubmit(`admin/sections/${id}`);
  };

  const validate = () => {
    const keysToCheck = Object.keys(formData).filter(
      (key) => key !== "_method"
    ) as (keyof FormDataType)[];

    let isValid;

    if (keysToCheck.length > 0) {
      isValid = id
        ? keysToCheck.some((key) => !!formData[key])
        : keysToCheck.length === Object.keys(soso).length - 1;
    } else isValid = false;

    return isValid;
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!id) {
      if (!validate()) toast.error("قم بإضافة صورة و اسم");
      else {
        addAction();
        if (!mutation.isError) setFormData({});
      }
    } else {
      if (validate()) {
        editAction();
      } else toast.error("قم بالتعديل");
    }
  };

  return (
    <Dialog>
      <DialogTrigger>{id ? <EditButton /> : <AddButton />}</DialogTrigger>
      <DialogContent
        dir="rtl"
        className="bg-white max-h-screen overflow-y-auto"
      >
        <DialogHeader>
          <DialogTitle className="w-fit">
            {id ? "تعديل" : "إضافة"} قسم
          </DialogTitle>
        </DialogHeader>
        <form
          onSubmit={(e) => submit(e)}
          className="flex flex-col gap-6 justify-center items-center"
        >
          <label className="w-full space-y-2">
          <AddImage
            inputName="image"
            initialImage={section ? fileURL + section?.image : undefined}
            viewImage={viewfile}
            handleChangeInput={handleChangeInput}
          />
          {errors.image &&
            errors.image.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
          <label className="w-full space-y-2">
            <p>الاسم بالعربي:</p>
            <input
              type="text"
              name="ar_name"
              className="p-2 w-full border border-gray-200 rounded-md"
              placeholder="اكتب الأسم"
              defaultValue={section?.ar_name}
              onChange={(e) => handleChangeInput(e)}
            />
            {errors.ar_name &&
            errors.ar_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
          <label className="w-full space-y-2">
            <p>الاسم بالانكليزي:</p>
            <input
              type="text"
              name="en_name"
              className="p-2 w-full border border-gray-200 rounded-md"
              placeholder="Type Name"
              dir="ltr"
              defaultValue={section?.en_name}
              onChange={(e) => handleChangeInput(e)}
            />
            {errors.en_name &&
            errors.en_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
          <label className="flex items-center justify-center col-span-2 gap-4 ">
            <p className="font-semibold"> قسم مميز:</p>
            <input
              type="checkbox"
              name="is_special"
              className="relative w-5 h-5"
              defaultChecked={section?.is_special === 1 ? true : false}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  is_special: e.currentTarget.checked ? 1 : 0,
                })
              }
            />
            {errors.is_special &&
            errors.is_special.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
          <button
            className="bg-amber-500 text-white rounded-md w-full border border-amber-500 transition-colors p-2 hover:bg-white hover:text-amber-500"
            type="submit"
          >
            {id ? "تعديل" : "إضافة"}
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
