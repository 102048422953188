import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/Dialog";
import EditButton from "../../ui/EditButton";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { usePOST } from "../../../ApiS";
import { toast } from "sonner";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { order } from "../../../types/order";
import { editOrder } from "../../../store/Orders/OrdersSlice";

export default function EditOrder({
  currentStatus,
  id,
}: {
  currentStatus: string;
  id: number;
}) {
  const statuses =
    currentStatus === "pending"
      ? ["rejected", "completed", "pending", "preparing"]
      : ["rejected", "completed", "preparing"];
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const dispatch = useDispatch();
  const { handleSubmit, mutation } = usePOST(
    { _method: "put" },
    (data) => onSuccess(data?.data?.data),
    (error) => onError(error)
  );

  const onError = (
    error: AxiosError<{ errors: string[]; message: string }>
  ) => {
    toast.dismiss("loading");
    error.response?.data.errors.forEach((er) => toast.error(er));
  };

  const onSuccess = (order: order) => {
    document.getElementById("close-trigger")?.click();
    toast.dismiss("loading");
    toast.success("تم التعديل بنجاح");
    dispatch(editOrder(order));
  };

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم التعديل`, {
        id: "loading",
      });
  }, [mutation]);

  const submit = () => {
    handleSubmit(`admin/orders/${id}/${selectedStatus}`);
  };
  return (
    <Dialog>
      <DialogTrigger>{<EditButton />}</DialogTrigger>
      <DialogContent dir="rtl" className="bg-white">
        <DialogHeader className="w-full space-y-4 ">
          <DialogTitle className="text-center">تعديل الطلب</DialogTitle>
        </DialogHeader>
        <div>
          <Select
            defaultValue={currentStatus}
            onValueChange={(value) => setSelectedStatus(value)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="القسم" />
            </SelectTrigger>
            <SelectContent className="z-9999 bg-white ">
              {statuses.map((status) => (
                <SelectItem
                  className="cursor-pointer hover:bg-slate-100 transition-colors"
                  value={status}
                >
                  <div
                    className={`px-3 py-1 rounded-full text-sm font-semibold ${
                      status === "completed"
                        ? "bg-green-100 text-green-600"
                        : status === "pending"
                        ? "bg-amber-100 text-amber-600"
                        : status === "preparing"
                        ? "bg-cyan-100 text-cyan-600"
                        : "bg-red-100 text-red-600"
                    }`}
                  >
                    {status}
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex w-full gap-4 items-center justify-center">
          <button
            onClick={() => submit()}
            className={`p-2 w-full rounded-md bg-red-500 border border-red-500 text-white transition-colors hover:bg-white hover:text-red-500`}
          >
            تأكيد
          </button>
          <button
            onClick={() => document.getElementById("close-trigger")?.click()}
            className="p-2 w-full border border-amber-500 rounded-md bg-white text-amber-500"
          >
            الغاء
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
