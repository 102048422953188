import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import Loader from "./Loader";
import { useFilter } from "../ApiS";
import { ArrowLeft, ArrowRight } from "lucide-react";

const Pagination = ({ pageCount }: { pageCount: number | undefined }) => {
  const { handlePageClick, searchParams } = useFilter();

  const [selectedPage, setSelectedPage] = useState(
    parseInt(searchParams.get("page")!) - 1 || 0
  );

  useEffect(() => {
    setSelectedPage(parseInt(searchParams.get("page")!) - 1 || 0);
  }, [searchParams]);

  const onPageChange = (selectedItem: { selected: number }) => {
    handlePageClick(selectedItem);
    setSelectedPage(selectedItem.selected);
    window.location.reload();
  };

  return (
    <>
      {pageCount ? (
        <div className="flex justify-center my-3">
          <ReactPaginate
            className="pagination flex"
            previousLabel={
              <ArrowRight className="text-slate-500 text-center my-auto h-full" />
            }
            nextLabel={
              <ArrowLeft className="text-slate-500 text-center my-auto h-full" />
            }
            breakLabel="..."
            pageCount={pageCount || 0}
            marginPagesDisplayed={3}
            pageRangeDisplayed={2}
            onPageChange={onPageChange}
            pageClassName="select-none rounded-full bg-blue-800 text-white  w-7 h-7"
            pageLinkClassName="w-full h-full flex justify-center items-center"
            forcePage={selectedPage}
          />
        </div>
      ) : null}
    </>
  );
};

export default Pagination;
