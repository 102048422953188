import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import SectionImage from "../../images/section.png";
import BrandImage from "../../images/brand.png";
import { user } from "../../types/user";

export interface UserState {
  value: user[];
}
const initialState: UserState = {
  value: [],
};
const UsersSlice = createSlice({
  name: "UsersSlice",
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<user>) => {
      state.value = [...state.value, action.payload];
    },
    removeUser: (state, action: PayloadAction<user>) => {
      state.value = state.value.filter((user) => user.id !== action.payload.id);
    },
    setUser: (state, action: PayloadAction<user[]>) => {
      state.value = action.payload;
    },
    editUser: (state, action: PayloadAction<user>) => {
      state.value = state.value.map((user) => {
        if (user.id === action.payload.id) {
          return action.payload;
        }
        return user;
      });
    },
  },
});

export const { addUser, removeUser, setUser, editUser } = UsersSlice.actions;
export default UsersSlice.reducer;
