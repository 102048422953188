import React from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../ApiS";
import Loader from "../../Loader";
import ProductTable from "../Products/ProductTable";
import { order } from "../../../types/order";

export default function OrderProducts() {
  const { id } = useParams();
  const {
    query: { data, isLoading, isError },
  } = useFetch<{ data: order }>(`admin/orders/${id}`, `orders/${id}`);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12  ">
      <h2 className="font-bold text-2xl">منتجات الطلب: {data?.data.id}</h2>
      <div className="overflow-y-auto">
        <ProductTable products={data?.data.products!} />
      </div>
    </div>
  );
}
