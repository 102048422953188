import React, { useEffect } from "react";

import AddButton from "../../ui/AddButton";
import { Link, useLocation } from "react-router-dom";
import { useFetch } from "../../../ApiS";
import { useDispatch } from "react-redux";
import AdminTable from "./AdminTable";
import { admin } from "../../../types/admin";
import { setAdmin } from "../../../store/Admins/AdminsSlice";
import Loader from "../../Loader";
import Pagination from "../../Pagination";

interface data {
  data: admin[];
  per_page: number;
  last_page: number;
}

function Admins() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { query: {data, isLoading, isError, error} } = useFetch<data>(
    `admin/admins?page=${searchParams.get("page")}"`,
    `admins?page=${searchParams.get("page")}`
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(setAdmin(data?.data));
    }
  }, [data]);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">المدراء</h2>
      <div className="overflow-x-auto">
        <AdminTable />
      </div>
      <Pagination pageCount={data?.last_page} />
      <Link to="/admins/add" className="fixed bottom-8 left-8">
        <AddButton />
      </Link>
    </div>
  );
}

export default Admins;
