import { useEffect } from "react";
import SectionsTable from "./SectionsTable";
import { setSections } from "../../../store/Sections/SectionsSlice";
import { useDispatch, useSelector } from "react-redux";
import AddDialog from "./AddEditDialog";
import { useLocation } from "react-router-dom";
import "../../../App.css";
import { section } from "../../../types/section";
import { useFetch } from "../../../ApiS";
import { RootState } from "../../../store/store";
import Loader from "../../Loader";
import Pagination from "../../Pagination";

interface data {
  data: section[];
  per_page: number;
  last_page: number;
}

function Sections() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const dispatch = useDispatch();
  const sections = useSelector((state: RootState) => state.sections.value);
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<data>(
    `admin/sections?page=${searchParams.get("page")}`,
    `sections?page=${searchParams.get("page")}`
  );

  useEffect(() => {
    dispatch(setSections(data?.data!));
  }, [data]);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">الأقسام</h2>
      <div className="overflow-x-auto">
        <SectionsTable sections={sections} />
      </div>
      <Pagination pageCount={data?.last_page} />
      <div className="fixed bottom-8 left-8">
        <AddDialog />
      </div>
    </div>
  );
}

export default Sections;
