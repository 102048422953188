import { Key, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import DeleteDialog from "../../DeleteDialog";

import EditButton from "../../ui/EditButton";
import { removeProduct } from "../../../store/Products/ProductsSlice";
import { product } from "../../../types/products";
import { removeMainProduct } from "../../../store/Products/MainProductsSlice";
import { toast } from "sonner";
import { fileURL, useDelete, useFilter, usePOST } from "../../../ApiS";
import { Check, X } from "lucide-react";
import ShowButton from "../../ui/ShowButton";

function Product({
  product,
  key,
  length,
  title,
}: {
  product: product;
  key: Key;
  title?: string;
  length: number;
}) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { searchParams } = useFilter();
  const isSubsection = searchParams.get("is_subsection")
  const {
    deleteItem,
    mutation: { isLoading },
  } = useDelete(
    () => onDelete(),
    () => {
      toast.error("Error while deleting.");
      toast.dismiss("loading");
    }
  );

  const onDelete = () => {
    toast.dismiss("loading");
    toast.success("تمت الإزالة");
    dispatch(removeProduct(product));
  };

  const { handleSubmit, mutation } = usePOST(
    { _method: "put", is_special: 0 },
    () => successPost(),
    () => {
      toast.error("Error while deleting.");
      toast.dismiss("loading");
    }
  );

  const successPost = () => {
    toast.dismiss("loading");
    toast.success("تمت الإزالة");
    dispatch(removeMainProduct(product));
  };

  useEffect(() => {
    if (mutation.isLoading || isLoading)
      toast.loading(`يتم الإزالة`, { id: "loading" });
  }, [mutation, isLoading]);

  const deleteAction = () => {
    if (pathname.includes("homePage/mainProducts")) {
      handleSubmit(`admin/${product.brand.id}/products/${product.id}`);
    } else {
      deleteItem(`admin/${product.brand.id}/products/${product.id}`);
    }
  };

  return (
    <div
      className={`grid grid-cols-7 ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark  "
      }`}
      key={key}
    >
      <div className="flex items-center justify-center flex-wrap gap-3 p-2.5 xl:p-5">
        <div className="flex-shrink-0">
          <img
            src={fileURL + product.image}
            alt="Brand"
            className="w-20 rounded"
          />
        </div>
        <p className="text-black dark:text-white md:block">{product.ar_name}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {product.price.toString()}$
        </p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{product.quantity}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {product.brand?.section?.ar_name}
        </p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{product.brand?.ar_name}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {product.is_special === 1 ? (
            <Check className="w-5 text-blue-800" />
          ) : (
            <X className="w-5 text-blue-800" />
          )}
        </p>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-3 p-2.5 xl:p-5">
        <Link to={isSubsection ? `/products/edit/${product.id}?is_subsection=true` : `/products/edit/${product.id}`}>
          {pathname.includes("orders") ? <ShowButton /> : <EditButton />}
        </Link>
       {!pathname.includes("orders") && <DeleteDialog action={deleteAction} title={"هل تريد حذف المنتج"} />}
      </div>
    </div>
  );
}

export default Product;
