import { useState } from "react";
import DeleteDialog from "./DeleteDialog";
import { FilesState, clickZoomInImage, fileURL } from "../ApiS";
import { image } from "./pages/Branches/AddEditBranch";

export default function Image({
  image,
  setViewImages,
  index,
  setImages,
}: {
  image: image;
  index: number;
  setViewImages: React.Dispatch<React.SetStateAction<image[] | undefined>>;
  setImages: React.Dispatch<React.SetStateAction<FilesState>>;
}) {
  const handleDelete = () => {
    if (image.uploaded) {
      setViewImages((prev) => prev?.filter((im, ind) => ind !== index));
    } else {
      setViewImages((prev) =>
        prev?.map((im) => {
          if (im.id === image.id) return { ...im, deleted: true };
          return im;
        })
      );
    }
  };

  return (
    <div className="relative">
      <img
        src={image.uploaded ? image.url : fileURL + image.url}
        onClick={clickZoomInImage}
        className="cursor-pointer w-full"
      />

      <div
        onClick={(e) => e.stopPropagation()}
        className="absolute left-2 top-2 flex gap-2"
      >
        <DeleteDialog title="هل تريد حذف الصورة؟" action={handleDelete} />
      </div>
    </div>
  );
}
