import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store/store";
import EditButton from "../../ui/EditButton";
import { toast } from "sonner";
import { addUser, editUser } from "../../../store/Users/UsersSlice";
import { user } from "../../../types/user";
import { fileURL, useFetch, usePOST } from "../../../ApiS";
import Loader from "../../Loader";
import { AxiosError } from "axios";
import AddImage from "../../AddImage";

type FormDataType = {
  id?: number;
  image?: File | string;
  first_name?: string;
  last_name?: string;
  username?: string;
  email?: string;
  phone?: string;
  password?: string;
  _method?: string;
};

type Errors = {
  image?: string[];
  first_name?: string[];
  last_name?: string[];
  username?: string[];
  email?: string[];
  phone?: string[];
  password?: string[];
};

function AddEditUsers() {
  const { id } = useParams();
  const users = useSelector((state: RootState) => state.users.value);
  const enabled = id ? users.length === 0 : false;
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<{ data: user }>(`admin/users/${id}`, "user", enabled);
  const [user, setUser] = useState(users?.find((b) => b.id.toString() === id));
  const [errors, setErrors] = useState<Errors>({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialData: FormDataType = { _method: id ? "put" : "post" };

  const {
    setFormData,
    handleSubmit,
    handleChangeInput,
    formData,
    mutation,
    viewfile,
  } = usePOST(
    initialData,
    (data) => onSuccess(data?.data?.data),
    (error) => onError(error)
  );

  useEffect(() => {
    if (data) {
      setUser(data?.data);
    }
  }, [data]);

  const onError = (error: AxiosError<{ errors: Errors; message: string }>) => {
    toast.dismiss("loading");
    toast.error(error.message);
    setErrors(error.response?.data.errors || {});
  };

  const onSuccess = (user: user) => {
    navigate("/users");
    if (id) {
      dispatch(editUser(user));
    } else {
      dispatch(addUser(user));
    }
    toast.success(`تم ${id ? `التعديل` : `الإضافة`}`);
    toast.dismiss("loading");
    setErrors({})
  };

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم ${id ? "التعديل" : "الإضافة"}`, { id: "loading" });
  }, [mutation.isLoading]);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData.username]);

  const addAction = () => {
    handleSubmit(`admin/users`);
  };

  const editAction = () => {
    handleSubmit(`admin/users/${id}`);
  };

  const validate = () => {
    const keysToCheck = Object.keys(formData).filter(
      (key) => key !== "_method"
    ) as (keyof FormDataType)[];
    console.log(keysToCheck);

    let isValid;

    if (keysToCheck.length > 0) {
      isValid = id
        ? keysToCheck.some(
            (key) =>
              formData[key] !== undefined &&
              formData[key] !== null &&
              formData[key] !== ""
          )
        : keysToCheck.length === 7;
    } else isValid = false;

    console.log(isValid);
    return isValid;
  };

  const submit = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.preventDefault();
    if (!id) {
      if (!validate()) toast.error("قم بتعبئة جميع الحقول");
      else {
        addAction();
        document.getElementById("close-trigger")?.click();
      }
    } else {
      if (validate()) {
        editAction();
        document.getElementById("close-trigger")?.click();
      } else toast.error("قم بالتعديل");
    }
  };

  return id && isLoading ? (
    <Loader />
  ) : id && isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-center text-2xl">
        {user ? "تعديل مستخدم: " + user?.username : "إضافة مستخدم"}
      </h2>
      <form className="flex flex-col gap-8 md:gap-12">
        <label>
          <AddImage
            inputName="image"
            initialImage={user ? fileURL + user?.image : undefined}
            viewImage={viewfile}
            handleChangeInput={handleChangeInput}
          />
          {errors.image &&
            errors.image.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
          <label className="w-full space-y-2">
            <p className="font-semibold">الاسم الاول:</p>
            <input
              name="first_name"
              defaultValue={user?.first_name}
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="اكتب الاسم الكامل"
              onChange={handleChangeInput}
            />
            {errors.first_name &&
            errors.first_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
          <label className="w-full space-y-2">
            <p className="font-semibold">الاسم الاخير:</p>
            <input
              name="last_name"
              defaultValue={user?.last_name}
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="اكتب الاسم الكامل"
              onChange={handleChangeInput}
            />
            {errors.last_name &&
            errors.last_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
          <label className="w-full space-y-2">
            <p className="font-semibold"> اسم المستخدم:</p>
            <input
              name="username"
              defaultValue={user?.username}
              onChange={handleChangeInput}
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="اسم المستخدم"
            />
            {errors.username &&
            errors.username.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
          <label className="w-full space-y-2">
            <p className="font-semibold">رقم التلفون :</p>
            <input
              name="phone_number"
              defaultValue={user?.phone_number}
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="اكتب رقم الهاتف"
              onChange={handleChangeInput}
            />
            {errors.phone &&
            errors.phone.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
          <label className="w-full space-y-2">
            <p className="font-semibold"> الايميل:</p>
            <input
              name="email"
              defaultValue={user?.email}
              onChange={handleChangeInput}
              type="email"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="الايميل"
            />
            {errors.email &&
            errors.email.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
          <label className="w-full space-y-2">
            <p className="font-semibold">كلمة المرور:</p>
            <input
              name="password"
              defaultValue={user?.password}
              type="password"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="اكتب كلمة السر"
              onChange={handleChangeInput}
            />
            {errors.password &&
            errors.password.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
          </label>
        </div>
        <input
          type="submit"
          onClick={(e) => submit(e)}
          value={id ? "تعديل" : "حفظ"}
          className="bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
        />
      </form>
    </div>
  );
}

export default AddEditUsers;
