import { useEffect } from "react";
import BranchesTable from "../Branches/BranchesTable";
import { branch } from "../../../types/branch";
import { useFetch } from "../../../ApiS";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import { setMainBranches } from "../../../store/Branches/MainBranchesSlice";
import { RootState } from "../../../store/store";
import AddButton from "../../ui/AddButton";
import { Link } from "react-router-dom";

type dataType = {
  data: {
    data: branch[];
  };
};

function MainBranches() {
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<dataType>("admin/branches?is_special=1", "specialBranches");
  const dispatch = useDispatch();
  const specialBranches = useSelector(
    (state: RootState) => state.mainBranches.value
  );

  useEffect(() => {
    if (data) {
      dispatch(setMainBranches(data?.data?.data));
    }
  }, [data]);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">أبرز الفروع</h2>
      <BranchesTable branches={specialBranches} />
      <Link to="/branches/add" className="fixed bottom-8 left-8">
        <AddButton />
      </Link>
    </div>
  );
}

export default MainBranches;
