import React, { useRef } from "react";
import EditButton from "./ui/EditButton";

export default function AddImage({
  initialImage,
  viewImage,
  handleChangeInput,
  inputName
}: {
  initialImage?: string;
  viewImage: string | undefined;
  handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputName: string
}) {
  const inputImageRef = useRef<any>(null);

  return (
    <div className="w-full">
      <input
        type="file"
        hidden
        name={inputName}
        ref={inputImageRef}
        accept="image/*"
        onChange={handleChangeInput}
      />
      {viewImage || initialImage ? (
        <div className="space-y-2">
          <div className="relative w-60 md:w-80 mx-auto">
            <img src={viewImage ? viewImage : initialImage} className="w-full rounded-md" />
            <div
              onClick={(e) => {
                inputImageRef?.current.click();
                e.preventDefault();
              }}
              className="absolute left-2 top-2"
            >
              <EditButton />
            </div>
          </div>
        </div>
      ) : (
        <button
          onClick={(e) => {
            inputImageRef?.current.click();
            e.preventDefault();
          }}
          className={`${
            viewImage && "hidden"
          } rounded-lg border w-full border-amber-500 bg-white text-amber-500 transition-colors hover:bg-white hover:text-amber-500 p-2`}
        >
          قم بتحميل صورة من جهازك
        </button>
      )}
    </div>
  );
}
