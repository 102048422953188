import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { message } from "../../../types/chat";
import { usePOST } from "../../../ApiS";
import { addMessage, replaceMessage } from "../../../store/Chats/MessagesSlice";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";

export type FormDataType = {
  content?: string;
};

function ChatInput({ recieverId }: { recieverId: number }) {
  const dispatch = useDispatch();
  const initialData: FormDataType = {};
  const inputRef = useRef<any>(null);
  const [messageQueue, setMessageQueue] = useState<message[]>([]);
  const [isSending, setIsSending] = useState(false);

  const { handleSubmit } = usePOST(
    initialData,
    (data) => onSuccess(data?.data?.data!),
    (error) => onError(error)
  );

  const onError = (error: AxiosError<{ errors: string[]; message: string }>) => {
    toast.error(error.response?.data.errors.at(0));
    setIsSending(false);
  };

  const onSuccess = (message: message) => {
    dispatch(replaceMessage(message));
    setIsSending(false);
  };

  const sendMessage = () => {
    const value = inputRef.current.value;
    if (value) {
      const tempMessage = {
        id: Date.now(), 
        receiver_id: recieverId,
        content: value,
        loading: true,
      };

      dispatch(addMessage(tempMessage));
      setMessageQueue((prevQueue) => [...prevQueue, tempMessage]);
      inputRef.current.value = "";
    }
  };

  const processQueue = () => {
    if (messageQueue.length === 0) {
      return;
    }
    const messageToSend = messageQueue[0];
    console.log("content " + messageToSend.content)
    setIsSending(true);
    setMessageQueue((prevQueue) => prevQueue.slice(1));
    handleSubmit(`admin/chat/users/${messageToSend.receiver_id}/messages`, {
      content: messageToSend.content,
    });
  };
  
  useEffect(() => {
    if(!isSending)
      processQueue()
  }, [messageQueue, isSending])

  return (
    <div className="flex gap-2 w-full">
      <input
        id="chat-input"
        ref={inputRef}
        name="content"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            sendMessage();
          }
        }}
        type="text"
        className="h-full flex-1 border border-slate-300 rounded p-2 md:p-3 bg-white "
        placeholder="اكتب الرسالة"
      />
      <button
        onClick={sendMessage}
        className="rounded-md p-3 md:p-4 bg-amber-500"
      >
        <Send />
      </button>
    </div>
  );
}

function Send() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide text-white lucide-send"
    >
      <path d="m22 2-7 20-4-9-9-4Z" />
      <path d="M22 2 11 13" />
    </svg>
  );
}

export default ChatInput;
