import { Key, useEffect, useState } from "react";
import { brand } from "../../../../types/brand";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import EditDialog from "./AddEditDialogBrands";
import DeleteDialog from "../../../DeleteDialog";
import { fileURL, useDelete, useFilter } from "../../../../ApiS";
import { toast } from "sonner";
import { removeBrand } from "../../../../store/Brands/BrandsSlice";

function Brand({
  brand,
  key,
  length,
}: {
  brand: brand;
  key: Key;
  length: number;
}) {
  const dispatch = useDispatch();
  const { searchParams } = useFilter();
  const isSubsection = searchParams.get("is_subsection")
  console.log(isSubsection)
  const { deleteItem, mutation } = useDelete(
    () => onDelete(),
    () => {
      toast.error("Error while deleting.");
      toast.dismiss("loading");
    }
  );

  useEffect(() => {
    if (mutation.isLoading) toast.loading(`يتم الإزالة`, { id: "loading" });
  }, [mutation]);

  const onDelete = () => {
    toast.dismiss("loading");
    toast.success("تمت الإزالة");
    dispatch(removeBrand(brand));
  };

  return (
    <div
      className={`grid grid-cols-4 ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark"
      }`}
      key={key}
    >
      <div className="flex items-center gap-3 p-2.5 xl:p-5">
        <div className="flex-shrink-0">
          <img
            src={fileURL + brand.image}
            alt="Brand"
            className="w-20 rounded"
          />
        </div>
        <p className="text-black dark:text-white md:block">{brand.ar_name}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{brand.created_at}</p>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-3 p-2.5 xl:p-5">
        <EditDialog id={brand.id} />
        <DeleteDialog
          action={() => deleteItem(`admin/sections/${brand.id}`)}
          title={"هل تريد حذف الماركة؟"}
        />
      </div>

      <div className="flex gap-2 items-center justify-center p-2.5 xl:p-5">
        {!brand.has_products && !brand.has_sub_sections && !isSubsection ? (
          <>
            <Link
              to={`/products/${brand.id}`}
              className="bg-white text-amber-500 p-1 border border-amber-500 rounded text-center flex items-center justify-center"
            >
              إضافة المنتجات
            </Link>
            <Link
              to={`/sections/${brand.id}/brands?page=1&is_subsection=true`}
              className="bg-white text-amber-500 p-1 border border-amber-500 rounded text-center flex items-center justify-center"
            >
              إضافة الأقسام
            </Link>
          </>
        ) : brand.has_sub_sections && !isSubsection ? (
          <Link
            to={`/sections/${brand.id}/brands?page=1&is_subsection=true`}
            className="bg-white text-amber-500 p-1 border border-amber-500 rounded text-center flex items-center justify-center"
          >
            إضافة الأقسام
          </Link>
        ) : (
          <Link
            to={isSubsection ? `/products/${brand.id}?is_subsection=true` : `/products/${brand.id}`}
            className="bg-white text-amber-500 p-1 border border-amber-500 rounded text-center flex items-center justify-center"
          >
            إضافة المنتجات
          </Link>
        )}
      </div>
    </div>
  );
}

export default Brand;
