import { useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DefaultLayout from "./components/layout/DefaultLayout";
import PageTitle from "./components/PageTitle";
import Dashboard from "./components/pages/Dashboard";
import MainSections from "./components/pages/HomePage/MainSections";
import MainBranches from "./components/pages/HomePage/MainBranches";
import ContactUsMessages from "./components/pages/ContactUs/ContactUs";
import MainProducts from "./components/pages/HomePage/MainProducts";
import Sections from "./components/pages/Sections/Sections";
import Brands from "./components/pages/Sections/Brands/Brands";
import Branches from "./components/pages/Branches/Branches";
import AddEditBranch from "./components/pages/Branches/AddEditBranch";
import Offers from "./components/pages/Offers/Offers";
import AddEditOffers from "./components/pages/Offers/AddEditOffers";
import Products from "./components/pages/Products/Products";
import AddEditProducts from "./components/pages/Products/AddEditProducts";
import Users from "./components/pages/Users/Users";
import AddEditUsers from "./components/pages/Users/AddEditUsers";
import Chats from "./components/pages/Chats/Chats";
import ChatPreview from "./components/pages/Chats/ChatPreview";
import Login from "./components/pages/Auth/Login";
import ForgitPassword from "./components/pages/Auth/ForgetPassword";

import ChangePassword from "./components/pages/Auth/ChangePassword";
import Code from "./components/pages/Auth/Code";

import EditProfile from "./components/pages/Profile/EditProfile";
import Admins from "./components/pages/Admins/Admins";
import AddEditAdmin from "./components/pages/Admins/AddEditAdmin";
import Sliders from "./components/pages/Sliders/Sliders";
import { RequireAuth } from "./ApiS";
import AboutUs from "./components/pages/SiteInfo/Aboutus/AboutUs";
import Orders from "./components/pages/Orders/Orders";
import ContactUs from "./components/pages/SiteInfo/ContactUs/ContactUs";
import Footer from "./components/pages/SiteInfo/Footer/Footer";
import Infos from "./components/pages/HomePage/Infos";
import "./App.css";
import OrderProducts from "./components/pages/Orders/OrderProducts";
import OrderLocation from "./components/pages/Orders/OrderLocation";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <>
              <PageTitle title="Diaa Dashboard | Login" />
              <Login />
            </>
          }
        />
        <Route
          path="/forget"
          element={
            <>
              <PageTitle title="Diaa Dashboard | Forget Password" />
              <ForgitPassword />
            </>
          }
        />
        <Route
          path="/code"
          element={
            <>
              <PageTitle title="Diaa Dashboard |  code" />
              <Code />
            </>
          }
        />
      </Routes>
      <DefaultLayout>
        <RequireAuth>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | Home" />
                  <Dashboard />
                </>
              }
            />
            <Route
              element={
                <>
                  <PageTitle title="Home Page | Diaa Dashboard" />
                  <Outlet />
                </>
              }
            >
              <Route
                index
                path="/homePage/infos"
                element={
                  <>
                    <Infos />
                  </>
                }
              />
              <Route
                index
                path="/homePage/mainSections"
                element={
                  <>
                    <MainSections />
                  </>
                }
              />
              <Route
                index
                path="/homePage/mainProducts"
                element={
                  <>
                    <MainProducts />
                  </>
                }
              />
              <Route
                index
                path="/homePage/mainBranches"
                element={
                  <>
                    <MainBranches />
                  </>
                }
              />
            </Route>
            <Route
              element={
                <>
                  <PageTitle title="Diaa Dashboard | Site Info" />
                  <Outlet />
                </>
              }
            >
              <Route
                index
                path="/siteInfo/contactUs"
                element={
                  <>
                    <ContactUs />
                  </>
                }
              />
              <Route
                index
                path="/siteInfo/aboutUs"
                element={
                  <>
                    <AboutUs />
                  </>
                }
              />
              <Route
                index
                path="/siteInfo/footer"
                element={
                  <>
                    <Footer />
                  </>
                }
              />
            </Route>
            <Route
              index
              path="/sliders"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | Sliders" />
                  <Sliders />
                </>
              }
            />
            <Route
              index
              path="/contactUs"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | Contact Us" />
                  <ContactUsMessages />
                </>
              }
            />
            <Route
              path="/chats"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | Messages" />
                  {!pathname.includes("/reply") ? <Chats /> : <Outlet />}
                </>
              }
            >
              <Route
                path="/chats/reply/:id"
                element={
                  <>
                    <PageTitle title="Diaa Dashboard | Messages" />
                    <ChatPreview />
                  </>
                }
              ></Route>
            </Route>
            <Route
              path="/sections"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | Sections" />
                  {!pathname.includes("brands") ? <Sections /> : <Outlet />}
                </>
              }
            >
              <Route
                index
                path="/sections/:sectionId/brands/"
                element={<Brands />}
              />
            </Route>
            <Route
              path="/branches"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | Branches" />
                  {!pathname.includes("/add") && !pathname.includes("/edit") ? (
                    <Branches />
                  ) : (
                    <Outlet />
                  )}
                </>
              }
            >
              <Route
                index
                path="/branches/edit/:id"
                element={<AddEditBranch />}
              />
              <Route index path="/branches/add" element={<AddEditBranch />} />
            </Route>
            <Route
              path="/offers"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | offers" />
                  {!pathname.includes("/add") && !pathname.includes("/edit") ? (
                    <Offers />
                  ) : (
                    <Outlet />
                  )}
                </>
              }
            >
              <Route
                index
                path="/offers/edit/:id"
                element={<AddEditOffers />}
              />
              <Route index path="/offers/add" element={<AddEditOffers />} />
            </Route>

            <Route
              path="/products"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | products" />
                  {!pathname.includes("/add") &&
                  !pathname.includes("/edit") &&
                  !pathname.includes("/:brandId") ? (
                    <Products />
                  ) : (
                    <Outlet />
                  )}
                </>
              }
            >
              <Route index path="/products/:brandId" element={<Products />} />
              <Route
                index
                path="/products/edit/:productId"
                element={<AddEditProducts />}
              />
              <Route
                index
                path="/products/:brandId/add"
                element={<AddEditProducts />}
              />
              <Route index path="/products/add" element={<AddEditProducts />} />
            </Route>
            <Route
              path="/users"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | users" />
                  {!pathname.includes("/add") && !pathname.includes("/edit") ? (
                    <Users />
                  ) : (
                    <Outlet />
                  )}
                </>
              }
            >
              <Route index path="/users/edit/:id" element={<AddEditUsers />} />
              <Route index path="/users/add" element={<AddEditUsers />} />
            </Route>
            <Route
              index
              path="/changePassword"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | change-password" />
                  <ChangePassword />
                </>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | Profile" />
                  <EditProfile />
                </>
              }
            />
            <Route
              path="/orders"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | Orders" />
                  {pathname.includes("orderProducts") || pathname.includes("orderLocation") ? <Outlet /> : <Orders />}
                </>
              }
            >
              <Route path=":id/orderProducts" element={<OrderProducts />}/>
              <Route path=":id/orderLocation" element={<OrderLocation />}/>
            </Route>
            <Route
              path="/admins"
              element={
                <>
                  <PageTitle title="Diaa Dashboard | admins" />
                  {!pathname.includes("/add") && !pathname.includes("/edit") ? (
                    <Admins />
                  ) : (
                    <Outlet />
                  )}
                </>
              }
            >
              <Route index path="/admins/edit/:id" element={<AddEditAdmin />} />
              <Route index path="/admins/add" element={<AddEditAdmin />} />
            </Route>
          </Routes>
        </RequireAuth>
      </DefaultLayout>
    </>
  );
}

export default App;
