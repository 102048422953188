// LoginPage.tsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { fileURL, useFetch, usePOST } from "../../../ApiS";
import AddImage from "../../AddImage";
import { admin } from "../../../types/admin";
import { AxiosError } from "axios";
import { toast } from "sonner";

export interface DataType {
  total: number;
  per_page: number;
  data: admin;
}

type FormDataType = {
  username?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  image?: File;
  email?: string;
  _method: string;
};

type Errors = {
  username?: string[];
  first_name?: string[];
  email?: string[];
  last_name?: string[];
  phone_number?: string[];
  image?: string[];
};

const EditProfile: React.FC = () => {
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<DataType>("admin/profile", "profile");
  const [erros, setErrors] = useState<Errors>({});
  const initialData: FormDataType = { _method: "put" };

  const { mutation, handleChangeInput, handleSubmit, formData, viewfile } =
    usePOST(
      initialData,
      () => onSuccess(),
      (error) => onError(error)
    );

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم التعديل`, {
        id: "loading",
      });
  }, [mutation]);

  const onError = (error: AxiosError<{ errors: Errors; message: string }>) => {
    toast.dismiss("loading");
    toast.error(error.message);
    setErrors(error.response?.data.errors || {});
  };

  const onSuccess = () => {
    toast.dismiss("loading");
    toast.success("تم التعديل");
    setErrors({})
  };

  const validate = () => {
    const keysToCheck = Object.keys(formData).filter(
      (key) => key !== "_method"
    ) as (keyof FormDataType)[];

    let isValid;

    if (keysToCheck.length > 0) {
      isValid = keysToCheck.some((key) => !!formData[key]);
    } else isValid = false;

    return isValid;
  };

  const submit = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (validate()) handleSubmit("admin/profile/update");
    else toast.error("قم بالتعديل");
  };

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="flex-col items-center justify-center h-screen bg-gray-100 mt-2 ">
      <h1 className="text-xl font-bold text-center my-2">
        تغيير البروفايل
      </h1>

      <form
        onSubmit={(e) => e.preventDefault()}
        className="bg-white shadow-md rounded px-2 md:px-8 pt-6 pb-8 mb-4 w-[55%] max-md:w-full  mx-auto"
      >
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="image">
            الصورة:
          </label>
          <AddImage
            inputName="image"
            initialImage={
              data?.data.image ? fileURL + data?.data.image : undefined
            }
            viewImage={viewfile}
            handleChangeInput={handleChangeInput}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="username"
          >
            اسم المستخدم:
          </label>
          <input
            className="p-2 w-full border border-slate-400 rounded-md"
            id="username"
            type="text"
            placeholder="اسم المستخدم"
            name="username"
            onChange={handleChangeInput}
            defaultValue={data?.data.username}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="first_name"
          >
            الاسم الأول:
          </label>
          <input
            className="p-2 w-full border border-slate-400 rounded-md"
            id="first_name"
            type="text"
            placeholder="الاسم الأول"
            name="first_name"
            onChange={handleChangeInput}
            defaultValue={data?.data.first_name}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="last_name"
          >
            الاسم الأخير:
          </label>
          <input
            className="p-2 w-full border border-slate-400 rounded-md"
            id="last_name"
            type="text"
            placeholder="الاسم الأخير"
            name="last_name"
            onChange={handleChangeInput}
            defaultValue={data?.data.last_name}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
            الايميل:
          </label>
          <input
            className="p-2 w-full border border-slate-400 rounded-md"
            id="email"
            type="email"
            placeholder="الايميل"
            name="email"
            onChange={handleChangeInput}
            defaultValue={data?.data.email}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="phone_number"
          >
            رقم الموبايل:
          </label>
          <input
            className="p-2 w-full border border-slate-400 rounded-md"
            id="phone_number"
            type="text"
            placeholder="رقم الموبايل"
            name="phone_number"
            onChange={handleChangeInput}
            defaultValue={data?.data.phone_number}
          />
        </div>
        <div className="w-full flex justify-center">
          <input
            onClick={(e) => submit(e)}
            type="submit"
            value={"تعديل"}
            className="bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
          />
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
