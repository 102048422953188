import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { offer } from "../../types/offer";
import { order } from "../../types/order";

export interface OfferState {
  value: order[];
}
const initialState: OfferState = {
  value: [],
};
const OffersSlice = createSlice({
  name: "OffersSlice",
  initialState,
  reducers: {
    addOrder: (state, action: PayloadAction<order>) => {
      state.value = [...state.value, action.payload];
    },
    removeOrder: (state, action: PayloadAction<order>) => {
      state.value = state.value.filter(
        (offer) => offer.id !== action.payload.id
      );
    },
    setOrders: (state, action: PayloadAction<order[]>) => {
      state.value = action.payload;
    },
    editOrder: (state, action: PayloadAction<order>) => {
      state.value = state.value.map((offer) => {
        if (offer.id === action.payload.id) {
          return action.payload;
        }
        return offer;
      });
    },
  },
});

export const { addOrder, removeOrder, setOrders, editOrder } =
  OffersSlice.actions;
export default OffersSlice.reducer;
