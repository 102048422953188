import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { chat } from "../../../types/chat";
import Message from "./Message";
import { Loader } from "lucide-react";

export default function ListMessages({
  chat,
  isFetching,
  last_page,
  per_page,
  page,
  setPage,
}: {
  chat: chat;
  isFetching: boolean;
  last_page: number;
  per_page: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) {
  const messages = [
    ...useSelector((state: RootState) => state.messages.value),
  ].reverse();
  const messageListRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (messageListRef.current) {
      const { scrollTop } = messageListRef.current;
      if (scrollTop === 0 && page < last_page && !isFetching) {
        setPage((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (messageList) {
        messageList.removeEventListener("scroll", handleScroll);
      }
    };
  }, [page, isFetching, last_page]);

  return (
    <div
      ref={messageListRef}
      className="flex-1 flex flex-col p-4 gap-4 overflow-y-auto"
    >
      {isFetching && (
        <div className="w-full flex justify-center">
          <Loader className="animate-spin text-blue-800" />
        </div>
      )}

      {messages?.map((message, index) => (
        <Message
          key={index}
          username={chat?.username!}
          message={message}
          isSameUser={
            index !== 0
              ? message.sender_id
                ? message.sender_id === messages.at(index - 1)?.sender_id
                : !message.sender_id && !messages.at(index - 1)?.sender_id
              : false
          }
        />
      ))}
    </div>
  );
}
