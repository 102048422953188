import React, { useState } from "react";

import AddEditDialog from "../Sections/AddEditDialog";
import { useDispatch, useSelector } from "react-redux";

import { addOffer } from "../../../store/Offers/OffersSlice";
import { Link, useLocation, useParams } from "react-router-dom";
import AddButton from "../../ui/AddButton";
import { addProduct, setProduct } from "../../../store/Products/ProductsSlice";
import ProductTable from "./ProductTable";
import { RootState } from "../../../store/store";
import { section } from "../../../types/section";
import { product } from "../../../types/products";
import { useFetch } from "../../../ApiS";
import Loader from "../../Loader";
import Pagination from "../../Pagination";

interface dataType {
  data: {
    data: product[];
    per_page: number;
    last_page: number;
  };
}

export default function Products() {
  const { brandId } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<dataType>(
    brandId
      ? `admin/${brandId}/products?page=${searchParams.get("page")}`
      : `admin/all/products?page=${searchParams.get("page")}`,
      brandId ? `products${brandId}?page=${searchParams.get("page")}` : `products?page=${searchParams.get("page")}`
  );
  const dispatch = useDispatch();
  dispatch(setProduct(data?.data?.data!));
  const products = useSelector((state: RootState) => state.products.value);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12  ">
      <h2 className="font-bold text-2xl">المنتجات</h2>
      <div className="overflow-x-auto">
        <ProductTable products={products} title="products" />
      </div>
      <Pagination pageCount={data?.data?.last_page} />
      <div className="fixed bottom-8 left-8">
        <Link to={brandId ? `/products/${brandId}/add` : `/products/add`}>
          <AddButton />
        </Link>
      </div>
    </div>
  );
}
