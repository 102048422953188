import { useEffect } from "react";
import ProductTable from "../Products/ProductTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { product } from "../../../types/products";
import { setMainProduct } from "../../../store/Products/MainProductsSlice";
import { useFetch } from "../../../ApiS";
import Loader from "../../Loader";
import { Link } from "react-router-dom";
import AddButton from "../../ui/AddButton";

type dataType = {
  data: {
    data: product[];
  };
};

function MainProducts() {
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<dataType>("admin/all/products?is_special=1", "specialProducts");
  const dispatch = useDispatch();
  const specialProducts = useSelector(
    (state: RootState) => state.mainProducts.value
  );

  useEffect(() => {
    if (data) {
      dispatch(setMainProduct(data?.data?.data));
    }
  }, [data]);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">أبرز المنتجات</h2>
      <ProductTable products={specialProducts} title="main products" />
      <div className="fixed bottom-8 left-8">
        <Link to={`/products/add`}>
          <AddButton />
        </Link>
      </div>
    </div>
  );
}

export default MainProducts;
