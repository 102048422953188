import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/Dialog";
import DeleteButton from "./ui/DeleteButton";
import EditButton from "./ui/EditButton";

export default function DeleteDialog({
  title,
  action,
  isEdit,
}: {
  title: string;
  action: () => void;
  isEdit?: boolean
}) {
  const handleDelete = () => {
    action();
    document.getElementById("close-trigger")?.click();
  };
  return (
    <Dialog>
      <DialogTrigger onClick={(e) => e.stopPropagation()}>
        {isEdit ? <EditButton /> :<DeleteButton />}
      </DialogTrigger>
      <DialogContent dir="rtl" className="bg-white">
        <DialogHeader className="w-full space-y-4 ">
          <DialogTitle className="text-center">{title}</DialogTitle>
          <DialogDescription className="text-center">
            لا يمكنك التراجع بعد ذلك
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full gap-4 items-center justify-center">
          <button
            onClick={() => handleDelete()}
            className={`p-2 w-full rounded-md bg-red-500 border border-red-500 text-white transition-colors hover:bg-white hover:text-red-500`}
          >
            تأكيد
          </button>
          <button
            onClick={() => document.getElementById("close-trigger")?.click()}
            className="p-2 w-full border border-amber-500 rounded-md bg-white text-amber-500"
          >
            الغاء
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
