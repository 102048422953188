import Brand from "./Brand";
import { brand } from "../../../../types/brand";

function BrandsTable({ brands }: { brands: brand[] }) {
  return (
    <div className="rounded-sm border border-stroke min-w-[600px] bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col">
        <div className="grid rounded-sm bg-amber-50 text-amber-500 grid-cols-4">
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الماركة
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              تاريخ الإنشاء
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              تعديل
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              العمليات
            </h5>
          </div>
        </div>

        {brands?.length > 0 ? (
          brands?.map((brand, key) => (
            <Brand brand={brand} key={key} length={brands?.length} />
          ))
        ) : (
          <div className="mt-12 mb-12">
            <p className="font-bold text-red-600"> لايوجد اي ماركات لعرضها</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default BrandsTable;
