import React, { useEffect, useState } from "react";
import logo from "./Logo.svg";
import Loader from "../../Loader";
import { usePOST } from "../../../ApiS";
import { AxiosError } from "axios";
import { toast } from "sonner";

type FormDataType = {
  password?: string;
  old_password?: string;
  password_confirmation?: string;
  _method: string;
};

type Errors = {
  password?: string[];
  old_password?: string[];
  password_confirmation?: string[];
}

const ChangePassword: React.FC = () => {
  const [errors, setErrors] = useState<Errors>({});
  const initialData: FormDataType = { _method: "put" };

  const { mutation, handleChangeInput, handleSubmit, formData, viewfile } =
    usePOST(
      initialData,
      () => onSuccess(),
      (error) => onError(error)
    );

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم التعديل`, {
        id: "loading",
      });
  }, [mutation]);

  const onError = (error: AxiosError<{ errors: Errors; message: string }>) => {
    toast.dismiss("loading");
    toast.error(error.message);
    setErrors(error.response?.data.errors || {});
  };

  const onSuccess = () => {
    toast.dismiss("loading");
    toast.success("تم التعديل");
    setErrors({})
  };

  const validate = () => {
    const keysToCheck = Object.keys(formData).filter(
      (key) => key !== "_method"
    ) as (keyof FormDataType)[];

    let isValid;

    if (keysToCheck.length > 0) {
      isValid = keysToCheck.some((key) => !!formData[key]);
    } else isValid = false;

    return isValid;
  };

  const submit = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (validate()) {
      if(formData.password === formData.password_confirmation)
        handleSubmit("admin/change-password")
      else
        setErrors({...errors, password_confirmation: ["Password confirmation must be identical with password."]})
    }
    else toast.error("قم بالتعديل");
  };

  return (
    <div className="flex-col items-center justify-center bg-gray-100 mt-2 ">
      <h1 className="text-xl font-bold text-center my-2">
        تغيير كلمة المرور
      </h1>
      <form onSubmit={(e) => e.preventDefault()} className="bg-white shadow-md rounded px-2 md:px-8 pt-6 pb-8 mb-4 w-[55%] max-md:w-full mx-auto">
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="old_password"
          >
            كلمة المرور القديمة:
          </label>
          <input
            className="p-2 w-full border border-slate-400 rounded-md"
            id="old_password"
            type="text"
            placeholder="كلمة المرور القديمة"
            name="old_password"
            onChange={handleChangeInput}
          />
          {errors.old_password &&
            errors.old_password.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="password"
          >
            كلمة المرور الجديدة:
          </label>
          <input
            className="p-2 w-full border border-slate-400 rounded-md"
            id="password"
            type="text"
            placeholder="كلمة المرور الجديدة"
            name="password"
            onChange={handleChangeInput}
          />
          {errors.password &&
            errors.password.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="password_confirmation"
          >
            تأكيد كلمة المرور:
          </label>
          <input
            className="p-2 w-full border border-slate-400 rounded-md"
            id="password_confirmation"
            type="text"
            placeholder="تأكيد كلمة المرور"
            name="password_confirmation"
            onChange={handleChangeInput}
          />
          {errors.password_confirmation &&
            errors.password_confirmation.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </div>
        <div className="w-full flex justify-center">
          <input
            onClick={(e) => submit(e)}
            type="submit"
            value={"تعديل"}
            className="bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
