import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { section } from "../../types/section";
import SectionImage from "../../images/section.png";
import BrandImage from "../../images/brand.png";

export interface SectionState {
  value: section[];
}

const initialState: SectionState = {
  value: [],
};

const sectionSlice = createSlice({
  name: "sectionSlice",
  initialState,
  reducers: {
    addSection: (state, action: PayloadAction<section>) => {
      state.value = [...state.value, action.payload];
    },
    removeSection: (state, action: PayloadAction<section>) => {
      state.value = state.value.filter(
        (section) => section.id !== action.payload.id
      );
    },
    setSections: (state, action: PayloadAction<section[]>) => {
      state.value = action.payload;
    },
    editSection: (state, action: PayloadAction<section>) => {
      state.value = state.value.map((section) => {
        if (section.id === action.payload.id) {
          return action.payload;
        }
        return section;
      });
    },
  },
});

export const { addSection, removeSection, setSections, editSection } =
  sectionSlice.actions;
export default sectionSlice.reducer;
