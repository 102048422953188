import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Chat from "./Chat";

function ChatTable() {
  const chats = useSelector((state: RootState) => state.chat.value);

  return (
    <div className="rounded-sm border min-w-[600px] border-stroke bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default">
      <div className="flex flex-col">
        <div className="grid grid-cols-4 rounded-sm bg-amber-50 text-amber-500 ">
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              المرسل
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              اخر رسالة
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              تاريخ اخر رسالة
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              العمليات
            </h5>
          </div>
        </div>

        {chats?.length > 0 ? (
          chats.map((chat, key) => (
            <Chat chat={chat} key={key} length={chats.length} />
          ))
        ) : (
          <div className="mt-12 mb-12">
            <p className="font-bold text-red-600"> لايوجد اي محادثات لعرضها</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChatTable;
