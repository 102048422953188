import React, { Key, useEffect } from "react";
import { contactMessage } from "../../../types/contactMessage";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useDelete, usePOST } from "../../../ApiS";
import { toast } from "sonner";
import { removeMessage } from "../../../store/ContactMesssage/ContactSlice";
import EditButton from "../../ui/EditButton";
import DeleteDialog from "../../DeleteDialog";
import { order } from "../../../types/order";
import EditOrder from "./EditOrder";
import { removeOrder } from "../../../store/Orders/OrdersSlice";

export default function Order({
  order,
  length,
  key,
}: {
  order: order;
  key: number;
  length: number;
}) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const {
    deleteItem,
    mutation: { isLoading },
  } = useDelete(
    () => onDelete(),
    () => toast.error("Error while deleting.")
  );

  const onDelete = () => {
    toast.dismiss("loading");
    toast.success("تمت الإزالة");
    dispatch(removeOrder(order));
  };

  useEffect(() => {
    if (isLoading) toast.loading(`يتم الإزالة`, { id: "loading" });
  }, [isLoading]);

  const deleteAction = () => {
    deleteItem(`admin/orders/${order.id}`);
  };

  return (
    <div
      className={`grid grid-cols-7  ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark  "
      }`}
      key={key}
    >
      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white md:block">{order.id}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <div
          className={`px-3 py-1 rounded-full text-sm font-semibold ${
            order?.en_status === "completed"
              ? "bg-green-100 text-green-600"
              : order?.en_status === "pending"
              ? "bg-amber-100 text-amber-600"
              : order?.en_status === "preparing"
              ? "bg-cyan-100 text-cyan-600"
              : "bg-red-100 text-red-600"
          }`}
        >
          {order?.en_status}
        </div>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{order.total_price}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <Link
          to={`/users/edit/${order.user.id}`}
          className="bg-white text-amber-500 p-1 border border-amber-500 rounded text-center flex items-center justify-center"
        >
          عرض
        </Link>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <Link
          to={`/orders/${order.id}/orderProducts`}
          className="bg-white text-amber-500 p-1 border border-amber-500 rounded text-center flex items-center justify-center"
        >
          عرض
        </Link>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <Link
          to={`/orders/${order.id}/orderLocation`}
          className="bg-white text-amber-500 p-1 border border-amber-500 rounded text-center flex items-center justify-center"
        >
          عرض
        </Link>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-3 p-2.5 xl:p-5">
        <EditOrder currentStatus={order.en_status} id={order.id} />
        {(order.en_status === "pending") && (
          <DeleteDialog action={deleteAction} title={"هل تريد حذف الطلب"} />
        )}
      </div>
    </div>
  );
}
