import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store/store";
import { toast } from "sonner";
import { addAdmin, editAdmin } from "../../../store/Admins/AdminsSlice";
import { fileURL, useFetch, usePOST } from "../../../ApiS";
import AddImage from "../../AddImage";
import { AxiosError } from "axios";
import { admin } from "../../../types/admin";
import Loader from "../../Loader";
import DeleteButton from "../../ui/DeleteButton";

type FormDataType = {
  id?: number;
  image?: File | string;
  username?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  email?: string;
  password?: string;
  _method?: string;
};

type Errors = {
  image?: string[];
  username?: string[];
  first_name?: string[];
  last_name?: string[];
  phone_number?: string[];
  email?: string[];
  password?: string[];
};

function AddEditAdmin() {
  const { id } = useParams();
  const navigate = useNavigate();
  let enabled = id ? true : false;
  const {
    query: { isLoading, isError, error, data },
  } = useFetch<{ data: admin }>(`admin/admins/${id}`, `admin${id}`, enabled);
  const [cofirmPassword, setConfirmPassword] = useState("");
  const admin = data?.data;
  const dispatch = useDispatch();
  const initialData: FormDataType = { _method: id ? "PUT" : "POST" };
  const [errors, setErrors] = useState<Errors>({});
  const {
    setFormData,
    handleSubmit,
    handleChangeInput,
    formData,
    mutation,
    viewfile,
  } = usePOST(
    initialData,
    (data) => onSuccess(data?.data?.data),
    (error) => onError(error)
  );

  const onError = (error: AxiosError<{ errors: Errors; message: string }>) => {
    toast.dismiss("loading");
    setErrors(error.response?.data.errors || {});
  };

  const onSuccess = (admin: admin) => {
    toast.dismiss("loading");
    toast.success(`تمت ${id ? `التعديل` : `الإضافة`}`);
    navigate("/admins");
    if (id) dispatch(editAdmin(admin));
    else dispatch(addAdmin(admin));
    setErrors({});
  };

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم ${id ? "التعديل" : "الإضافة"}`, { id: "loading" });
  }, [mutation]);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData._method]);

  const addAction = () => {
    handleSubmit("admin/admins");
  };

  const editAction = () => {
    handleSubmit(`admin/admins/${id}`);
  };

  const validate = () => {
    const keysToCheck = Object.keys(formData).filter(
      (key) => key !== "_method"
    ) as (keyof FormDataType)[];

    let isValid;

    if (formData.password) {
      if(formData.password !== cofirmPassword) {
        toast.error("كلمة المرور يجب ان تكون متطابقة مع التأكيد")
        return false
      }
    }

    if (keysToCheck.length > 0) {
      isValid = id
        ? keysToCheck.some(
            (key) =>
              formData[key] !== undefined &&
              formData[key] !== null &&
              formData[key] !== ""
          )
        : keysToCheck.length === 7;
    } else isValid = false;

    return isValid;
  };

  const submit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (!id) {
      if (!validate()) toast.error("قم بإضافة صورة و اسم");
      else {
        addAction();
        document.getElementById("close-trigger")?.click();
      }
    } else {
      if (validate()) {
        editAction();
        document.getElementById("close-trigger")?.click();
      } else toast.error("قم بالتعديل");
    }
  };

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-center text-2xl">
        {admin ? "تعديل الأدمن: " + admin?.username : "إضافة أدمن"}
      </h2>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="flex flex-col gap-4"
      >
        <div className="relative">
          <AddImage
            inputName="image"
            initialImage={admin?.image ? fileURL + admin?.image : undefined}
            viewImage={viewfile}
            handleChangeInput={handleChangeInput}
          />
          {errors.image && (
            <p className="text-red-500 text-sm" dir="auto">
              {errors.image[0]}
            </p>
          )}
        </div>
        <div className="flex items-center justify-center gap-6 w-full">
          <label className="w-full space-y-2">
            <p className="font-semibold">الاسم الكامل:</p>
            <input
              defaultValue={admin?.username}
              name="username"
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="الاسم"
              onChange={(e) => handleChangeInput(e)}
            />
            {errors.username && (
              <p className="text-red-500 text-sm" dir="auto">
                {errors.username[0]}
              </p>
            )}
          </label>
          <label className="w-full space-y-2">
            <p className="font-semibold">البريد الالكتروني:</p>
            <input
              defaultValue={admin?.email}
              name="email"
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="البريد الالكتروني"
              onChange={(e) => handleChangeInput(e)}
            />
            {errors.email && (
              <p className="text-red-500 text-sm" dir="auto">
                {errors.email[0]}
              </p>
            )}
          </label>
        </div>
        <div className="flex items-center justify-center gap-6 w-full">
          <label className="w-full space-y-2">
            <p className="font-semibold">الاسم الاول:</p>
            <input
              defaultValue={admin?.first_name}
              name="first_name"
              onChange={(e) => handleChangeInput(e)}
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="الاسم الاول"
            />
            {errors.first_name && (
              <p className="text-red-500 text-sm" dir="auto">
                {errors.first_name[0]}
              </p>
            )}
          </label>
          <label className="w-full space-y-2">
            <p className="font-semibold">الاسم الاخير:</p>
            <input
              defaultValue={admin?.last_name}
              name="last_name"
              onChange={(e) => handleChangeInput(e)}
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="الاسم الاخير"
            />
            {errors.last_name && (
              <p className="text-red-500 text-sm" dir="auto">
                {errors.last_name[0]}
              </p>
            )}
          </label>
        </div>
        <div className="flex items-center justify-center gap-6 w-full">
          <label className="w-full space-y-2">
            <p className="font-semibold">رقم الهاتف:</p>
            <input
              defaultValue={admin?.phone_number}
              name="phone_number"
              onChange={(e) => handleChangeInput(e)}
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="رقم الهاتف"
            />
            {errors.phone_number && (
              <p className="text-red-500 text-sm" dir="auto">
                {errors.phone_number[0]}
              </p>
            )}
          </label>
          <label className="w-full space-y-2">
            <p className="font-semibold">كلمة السر:</p>
            <input
              defaultValue={admin?.password}
              name="password"
              onChange={(e) => handleChangeInput(e)}
              type="text"
              className="p-2 w-full border border-slate-300 rounded-md"
              placeholder="كلمة السر"
            />
            {errors.password && (
              <p className="text-red-500 text-sm" dir="auto">
                {errors.password[0]}
              </p>
            )}
          </label>
        </div>
        <label className="w-1/2 mx-auto space-y-2">
          <p className="font-semibold"> تأكيد كلمة السر:</p>
          <input
            value={cofirmPassword}
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
            name="password_confirmation"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="تأكيد كلمة السر"
          />
        </label>

        <button
          className="bg-amber-500 w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
          onClick={(e) => submit(e)}
          type="submit"
        >
          {id ? "تعديل" : "حفظ"}
        </button>
      </form>
    </div>
  );
}

export default AddEditAdmin;
