import { Key, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import EditDialog from "../Sections/AddEditDialog";
import DeleteDialog from "../../DeleteDialog";
import { offer } from "../../../types/offer";

import AddButton from "../../ui/AddButton";
import EditButton from "../../ui/EditButton";
import {
  editProduct,
  removeProduct,
} from "../../../store/Products/ProductsSlice";
import { product } from "../../../types/products";
import { user } from "../../../types/user";
import { removeUser } from "../../../store/Users/UsersSlice";
import { fileURL, useDelete } from "../../../ApiS";
import { toast } from "sonner";

function User({ user, key, length }: { user: user; key: Key; length: number }) {
  const dispatch = useDispatch();
  const { deleteItem, mutation } = useDelete(
    () => onDelete(),
    () => {toast.error("Error while deleting."); toast.dismiss("loading")}
  );

  useEffect(() => {
    if (mutation.isLoading) toast.loading(`يتم الإزالة`, { id: "loading" });
  }, [mutation]);

  const onDelete = () => {
    toast.dismiss("loading");
    toast.success("تمت الإزالة");
    dispatch(removeUser(user));
  };

  const deleteAction = () => {
    deleteItem(`admin/users/${user.id}`);
  };

  return (
    <div
      className={`grid grid-cols-5 ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark"
      }`}
      key={key}
    >
      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{user.id}</p>
      </div>
      <div className="flex items-center justify-center gap-3 p-2.5 xl:p-5">
        {user.image && (
          <div className="flex-shrink-0">
            <img
              src={fileURL + user.image}
              alt="user"
              className="w-20 rounded"
            />
          </div>
        )}
        <p className="text-black dark:text-white md:block">{user.username}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black overflow-x-auto break-words dark:text-white">
          {user.email}
        </p>
      </div>
      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black overflow-x-auto break-words dark:text-white">
          {user.phone_number}
        </p>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-3 p-2.5 xl:p-5">
        <Link to={`/users/edit/${user.id}`}>
          <EditButton />
        </Link>
        <DeleteDialog action={deleteAction} title={"هل تريد حذف المستخدم"} />
      </div>
    </div>
  );
}

export default User;
