import React, { useEffect, useRef, useState } from "react";  
import EditButton from "./ui/EditButton";  

function Video({  
  viewVideo,  
  setViewVideo,  
  setFormData,  
  name,  
}: {  
  viewVideo: string | undefined;  
  setViewVideo: React.Dispatch<React.SetStateAction<string | undefined>>;  
  setFormData: React.Dispatch<React.SetStateAction<any>>;  
  name: string;  
}) {  
  const inputRef = useRef<HTMLInputElement | null>(null);  

  const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {  
    const file = event.target.files?.[0];  
    if (file) {  
      const videoURL = URL.createObjectURL(file); 
      setViewVideo(videoURL);
      setFormData((prev: any) => ({ ...prev, [name]: file }));  
    }  
  };  

  return (  
    <div className="h-full relative">  
      <h2 className="font-semibold mb-4 text-lg">الفيديو:</h2>  
      <button  
        onClick={() => inputRef.current?.click()}  
        className={`${  
          viewVideo ? "hidden" : ""  
        } mb-4 rounded-lg border border-amber-500 bg-amber-500 text-white transition-colors hover:bg-white hover:text-amber-500 p-2`}  
      >  
        انقر لتغيير الفيديو  
      </button>  
      <input  
        type="file"  
        name="video"  
        ref={inputRef}  
        accept="video/*"  
        hidden  
        onChange={handleVideoUpload}  
      />  
      {viewVideo && (  
        <div className="relative">  
          <video key={viewVideo} className="w-full mx-auto" controls>  
            <source src={viewVideo} type="video/mp4" />  
            Your browser does not support the video tag.  
          </video>  
          <div className="absolute left-2 top-2" onClick={() => inputRef.current?.click()}>  
            <EditButton />  
          </div>  
        </div>  
      )}  
    </div>  
  );  
}  

export default Video;