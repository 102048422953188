// LoginPage.tsx
import React, { useEffect, useState } from "react";
import logo from "./Logo.svg";
import { BiHide, BiShow } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { usePOST } from "../../../ApiS";
import { toast } from "sonner";

const Login: React.FC = () => {

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"], {
    doNotParse: true,
  });
  const [biShow, setBiShow] = useState(false);

  const { mutation, handleChangeInput, handleSubmit } = usePOST(
    {
      username: "",
      password: "",
    },
    (data) => {
      setCookie("token", data?.data?.data?.user?.token, { path: "/" });
      toast.dismiss("loading");
      navigate("/");
    },
    (error) => {toast.error(error.response.data.errors.at(0)); toast.dismiss("loading")}
  );

  useEffect(() => {
    if(mutation.isLoading)
      toast.loading("loading", {id: "loading"})
  }, [mutation.isLoading])

  return (
    <div className="flex-col items-center justify-center h-screen bg-gray-100 mt-12 ">
      <div className="bg-white shadow-md rounded-full px-8 pt-6 pb-8 mb-4 w-[50%] max-md:w-[97%]  mx-auto ">
        <img
          src={logo}
          alt=""
          className=" w-full h-[300px] max-md:h-[200px] "
        />
      </div>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-[55%] max-md:w-[97%]  mx-auto">
        <div className="mb-4">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="username"
          >
            Username
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            name="username"
            type="text"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit("admin/login");
              }
            }}
            placeholder="Enter your username"
            onChange={handleChangeInput}
            required
          />
        </div>
        <div className="mb-6 relative">
          <label
            className="block text-gray-700 font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            name="password"
            type={!biShow ? "password" : "text"}
            placeholder="Enter your password"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit("admin/login");
              }
            }}
            onChange={handleChangeInput}
            required
          />
          {biShow ? (
            <BiShow
              onClick={() => setBiShow(false)}
              size={22}
              className={`absolute right-3 bottom-1 cursor-pointer`}
            />
          ) : (
            <BiHide
              onClick={() => setBiShow(true)}
              size={22}
              className={`absolute right-3 bottom-1 cursor-pointer `}
            />
          )}
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            onClick={() => handleSubmit("admin/login")}
          >
            Sign In
          </button>

          <Link
            to="/forget"
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
          >
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
