import { useEffect } from "react";
import SectionsTable from "../Sections/SectionsTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { section } from "../../../types/section";
import { setMainSections } from "../../../store/Sections/MainSectionsSlice";
import { useFetch } from "../../../ApiS";
import Loader from "../../Loader";
import AddEditDialog from "../Sections/AddEditDialog";

type dataType = {
  data: section[];
};

function MainSections() {
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<dataType>("admin/sections?is_special=1", "specialSections");
  const dispatch = useDispatch();
  const specialSections = useSelector(
    (state: RootState) => state.mainSections.value
  );

  useEffect(() => {
    if (data) {
      dispatch(setMainSections(data?.data));
    }
  }, [data]);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">أبرز الأقسام</h2>
      <div className="overflow-x-auto">
        <SectionsTable sections={specialSections} />
      </div>
      <div className="fixed bottom-8 left-8">
        <AddEditDialog />
      </div>
    </div>
  );
}

export default MainSections;
