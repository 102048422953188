import React from "react";
import { useFetch } from "../../../ApiS";
import Loader from "../../Loader";
import UsersTable from "../Users/UsersTable";
import { user } from "../../../types/user";

type data = {
  data: user[];
};

function LastUsers() {
  const lastUsers = useFetch<data>("admin/home/users/last", "last users");

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
        آخر 5 مستخدمين
      </h4>
      {lastUsers.query.isLoading ? (
        <Loader />
      ) : (
        <div className="overflow-x-auto">
        <UsersTable users={lastUsers.query.data?.data!} />
        </div>
      )}
    </div>
  );
}

export default LastUsers;
