import { employee } from "../../../types/branch";
import Employee from "./Employee";
import DialogAddEmployee from "./DialogAddEmployee";

export default function EmployeeTable({
  setEmployees,
  employees,
}: {
  employees?: employee[];
  setEmployees: React.Dispatch<React.SetStateAction<employee[] | undefined>>;
}) {
  return (
    <div>
      <div className="rounded-sm border border-stroke min-w-[600px] bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex flex-col">
          <div className="grid grid-cols-6 rounded-lg bg-amber-50 text-amber-500 ">
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base text-start">
                صورة الموظف
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base text-start">
                اسم الموظف بالعربي
              </h5>
            </div>
            <div className="p-2.5 text-center xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base text-start">
                اسم الموظف بالانكليزي
              </h5>
            </div>
            <div className="p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                 الوظيفة بالعربي
              </h5>
            </div>
            <div className="p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                 الوظيفة بالانكليزي
              </h5>
            </div>
            <div className="p-2.5 text-center sm:block xl:p-5">
              <h5 className="text-sm font-medium uppercase xsm:text-base">
                العمليات
              </h5>
            </div>
          </div>

          {employees?.map((employee, key) => (
            <Employee
              employee={employee}
              key={key}
              length={employees?.length!}
              setEmployees={setEmployees}
            />
          ))}
        </div>
      </div>
      <div className="">
        <DialogAddEmployee setEmployees={setEmployees} />
      </div>
    </div>
  );
}
