import React, { useEffect, useState } from "react";
import OffersTable from "./OffersTable";
import { Link, useLocation } from "react-router-dom";
import AddButton from "../../ui/AddButton";

import { offer } from "../../../types/offer";
import { useFetch } from "../../../ApiS";
import { useDispatch } from "react-redux";
import { setOffer } from "../../../store/Offers/OffersSlice";
import Loader from "../../Loader";
import Pagination from "../../Pagination";

interface data {
  data: offer[];
  per_page: number;
  last_page: number;
}

export default function Offers() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<data>(
    `admin/offers?page=${searchParams.get("page")}`,
    `offers?page=${searchParams.get("page")}`
  );

  useEffect(() => {
    if (data) {
      dispatch(setOffer(data?.data!));
    }
  }, [data]);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">العروض</h2>
      <div className="overflow-x-auto">
        <OffersTable offers={data?.data!} isLoading={isLoading} />
      </div>
      <Pagination pageCount={data?.last_page} />
      <Link to="/offers/add" className="fixed bottom-8 left-8">
        <AddButton />
      </Link>
    </div>
  );
}
