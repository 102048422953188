import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import SectionImage from "../../images/section.png";
import BrandImage from "../../images/brand.png";
import { admin } from "../../types/admin";

export interface AdminState {
  value: admin[];
}
const initialState: AdminState = {
  value: [],
};
const OffersSlice = createSlice({
  name: "AdminSlice",
  initialState,
  reducers: {
    addAdmin: (state, action: PayloadAction<admin>) => {
      state.value = [...state.value, action.payload];
    },
    removeAdmin: (state, action: PayloadAction<admin>) => {
      state.value = state.value.filter(
        (admin) => admin.id !== action.payload.id
      );
    },
    setAdmin: (state, action: PayloadAction<admin[]>) => {
      state.value = action.payload;
    },
    editAdmin: (state, action: PayloadAction<admin>) => {
      state.value = state.value.map((admin) => {
        if (admin.id === action.payload.id) {
          return action.payload;
        }
        return admin;
      });
    },
  },
});

export const { addAdmin, removeAdmin, setAdmin, editAdmin } =
  OffersSlice.actions;
export default OffersSlice.reducer;
