import { Key, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import EditDialog from "../Sections/AddEditDialog";
import DeleteDialog from "../../DeleteDialog";
import { offer } from "../../../types/offer";
import { editOffer, removeOffer } from "../../../store/Offers/OffersSlice";
import EditOffer from "./AddEditOffers";
import AddButton from "../../ui/AddButton";
import EditButton from "../../ui/EditButton";
import { fileURL, useDelete } from "../../../ApiS";
import parse from "html-react-parser";
import { toast } from "sonner";

function Offer({
  offer,
  key,
  length,
}: {
  offer: offer;
  key: Key;
  length: number;
}) {
  const dispatch = useDispatch();
  
  const { deleteItem, mutation } = useDelete(
    () => onDelete(),
    () => {toast.error("Error while deleting."); toast.dismiss("loading")}
  );

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم الإزالة`, { id: "loading" });
  }, [mutation]);

  const onDelete = () => {
    toast.dismiss("loading");
    toast.success("تمت الإزالة");
    dispatch(removeOffer(offer));
  };

  return (
    <div
      className={`grid grid-cols-4 ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark"
      }`}
      key={key}
    >
      <div className="flex items-center gap-3 p-2.5 xl:p-5">
        <div className="flex-shrink-0">
          <img
            src={`${fileURL}${offer.image}`}
            alt="offer image"
            className="w-20 rounded"
          />
        </div>
        <p className="text-black dark:text-white md:block">
          {offer.ar_name && parse(offer.ar_name)}
        </p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {offer.ar_description && parse(offer.ar_description)}
        </p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {offer.discount}%
        </p>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-3 p-2.5 xl:p-5">
        <Link to={`/offers/edit/${offer.id}`}>
          <EditButton />
        </Link>
        <DeleteDialog
          action={() => deleteItem(`admin/offers/${offer.id}`)}
          title={"هل تريد حذف العرض"}
        />
      </div>
    </div>
  );
}

export default Offer;
