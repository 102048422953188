import { useLogout } from "../ApiS";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/Dialog";

export default function LogOut() {
  const { mutate: logout } = useLogout(() => console.log("error"));
  const handleLogout = () => {
    logout("admin/logout");
    document.getElementById("close-trigger")?.click();
  };

  return (
    <Dialog>
      <DialogTrigger className="flex items-center justify-center gap-2.5 text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="lucide lucide-log-out"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
          <polyline points="16 17 21 12 16 7" />
          <line x1="21" x2="9" y1="12" y2="12" />
        </svg>
        تسجيل الخروج{" "}
      </DialogTrigger>
      <DialogContent className="bg-white w-full">
        <DialogHeader className="w-full space-y-4 flex justify-center">
          <DialogTitle className="text-center text-lg font-bold">
            تسجيل الخروج
          </DialogTitle>
          <DialogDescription className="text-center text-2xl font-semibold ">
            هل تريد تسجيل الخروج{" "}
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full gap-4 items-center justify-center">
          <button
            onClick={() => handleLogout()}
            className="p-2 w-full rounded-md bg-red-500 border border-red-500 text-white transition-colors hover:bg-white hover:text-red-500"
          >
            تأكيد
          </button>
          <button
            onClick={() => document.getElementById("close-trigger")?.click()}
            className="p-2 w-full border border-amber-500 rounded-md bg-white text-amber-500"
          >
            الغاء
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
