import React, { useEffect } from "react";
import Loader from "../../Loader";
import { useFetch, useFilter } from "../../../ApiS";
import OrdersTable from "./OrdersTable";
import { order } from "../../../types/order";
import Pagination from "../../Pagination";
import { useDispatch, useSelector } from "react-redux";
import { setOrders } from "../../../store/Orders/OrdersSlice";
import { RootState } from "../../../store/store";

type dataType = {
  data: {
    data: order[];
    per_page: number;
    last_page: number;
  };
};

export default function Orders() {
  const { handleParamsClick, handleParamDelete, searchParams } = useFilter();
  const status = searchParams.get("status") || "";
  const page = searchParams.get("page") || "1";
  const apiUrl = `admin/orders?page=${page}${
    status ? `&status=${status}` : ""
  }`;
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<dataType>(
    apiUrl,
    `orders?page=${page}${status ? `&status=${status}` : ""}`
  );

  const dispatch = useDispatch();
  const orders = useSelector((state: RootState) => state.orders.value);

  useEffect(() => {
    if (data) {
      dispatch(setOrders(data.data?.data));
    }
  }, [data]);

  const handleStatusChange = (newStatus: number) => {
    handleParamsClick("status", newStatus);
  };

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">الطلبات</h2>

      <div className="flex justify-center gap-4">
        {["pending", "rejected", "preparing", "completed"].map(
          (statusValue, index) => (
            <button
              key={statusValue}
              onClick={() => handleStatusChange(index + 1)}
              className={`px-3 py-1 rounded-full text-sm font-semibold ${
                statusValue === "completed"
                  ? "bg-green-100 text-green-600"
                  : statusValue === "pending"
                  ? "bg-amber-100 text-amber-600"
                  : statusValue === "preparing"
                  ? "bg-cyan-100 text-cyan-600"
                  : "bg-red-100 text-red-600"
              }`}
            >
              {statusValue.charAt(0).toUpperCase() + statusValue.slice(1)}
            </button>
          )
        )}
        <button
          onClick={() => handleParamDelete("status")}
          className={`px-3 py-1 rounded-full text-sm font-semibold bg-slate-100 text-slate-500 `}
        >
          all
        </button>
      </div>

      <div className="overflow-x-auto">
        <OrdersTable orders={orders} />
      </div>
      <Pagination pageCount={data?.data?.last_page} />
    </div>
  );
}
