import React, { Key, useEffect } from "react";
import { branch } from "../../../types/branch";
import { Link, useLocation } from "react-router-dom";
import DeleteDialog from "../../DeleteDialog";
import EditButton from "../../ui/EditButton";
import { useDispatch } from "react-redux";
import { removeBranch } from "../../../store/Branches/BranchesSlice";
import { removeMainBranch } from "../../../store/Branches/MainBranchesSlice";
import { fileURL, useDelete, usePOST } from "../../../ApiS";
import { toast } from "sonner";
import { Check, X } from "lucide-react";

function Branch({
  branch,
  key,
  length,
}: {
  branch: branch;
  key: Key;
  length: number;
}) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const {
    deleteItem,
    mutation: { isLoading },
  } = useDelete(
    () => onDelete(),
    () => {
      toast.error("Error while deleting.");
      toast.dismiss("loading");
    }
  );

  const onDelete = () => {
    toast.dismiss("loading");
    dispatch(removeBranch(branch));
  };

  const { handleSubmit, mutation } = usePOST(
    { _method: "put", is_special: 0 },
    () => successPost(),
    () => {}
  );

  const successPost = () => {
    toast.dismiss("loading");
    toast.success("تمت الإزالة");
    dispatch(removeMainBranch(branch));
  };

  useEffect(() => {
    if (mutation.isLoading) toast.loading(`يتم الإزالة`, { id: "loading" });
  }, [mutation]);

  useEffect(() => {
    if (isLoading) toast.loading(`يتم الإزالة`, { id: "loading" });
  }, [isLoading]);

  const deleteAction = () => {
    if (pathname.includes("/homePage/mainBranches")) {
      handleSubmit(`admin/branches/${branch.id}`);
    } else {
      deleteItem(`admin/branches/${branch.id}`);
    }
  };

  return (
    <div
      className={`grid grid-cols-5 ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark"
      }`}
      key={key}
    >
      <div className="flex items-center gap-3 p-2.5 xl:p-5">
        <div className="flex-shrink-0">
          <img
            src={fileURL + branch?.images?.at(0)?.url}
            alt="Brand"
            className="w-20 rounded"
          />
        </div>
        <p className="text-black dark:text-white md:block">{branch?.ar_name}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {branch?.employees?.length}
        </p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{branch?.ar_location}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {branch.is_special === 1 ? (
            <Check className="w-5 text-blue-800" />
          ) : (
            <X className="w-5 text-blue-800" />
          )}
        </p>
      </div>

      <div className="flex flex-wrap gap-3 items-center justify-center p-2.5 xl:p-5">
        <Link to={`/branches/edit/${branch.id}`}>
          <EditButton />
        </Link>
        <DeleteDialog
          action={() => deleteAction()}
          title={"هل تريد حذف الفرع"}
        />
      </div>
    </div>
  );
}

export default Branch;
