import React, { Key } from "react";
import { chat } from "../../../types/chat";
import { Link } from "react-router-dom";
import { fileURL } from "../../../ApiS";

function Chat({ chat, key, length }: { key: Key; length: number; chat: chat }) {
  return (
    <div
      className={`grid grid-cols-4 ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark"
      }`}
      key={key}
    >
      <Link to={`/users/edit/${chat.id}`} className="flex items-center justify-center gap-3 p-2.5 xl:p-5 hover:bg-slate-100 transition-colors">
        {chat.image && (
          <div className="flex-shrink-0">
            <img
              src={`${fileURL}${chat.image}`}
              alt="Brand"
              className="w-10 rounded h-10"
            />
          </div>
        )}
        <p className="text-black dark:text-white md:block">
          {chat.first_name + " " + chat.last_name}
        </p>
      </Link>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {chat.last_message_content}
        </p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">
          {chat.last_message_time}
        </p>
      </div>

      <div className="flex flex-wrap items-center justify-center gap-3 p-2.5 xl:p-5">
        <Link
          className="border border-amber-500 rounded p-2 text-amber-500"
          to={`/chats/reply/${chat.id}/?page=1`}
        >
          الرد
        </Link>
      </div>
    </div>
  );
}

export default Chat;
