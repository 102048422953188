
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import AddButton from "../../ui/AddButton";
import UsersTable from "./UsersTable";
import { useFetch } from "../../../ApiS";
import { setUser } from "../../../store/Users/UsersSlice";
import { user } from "../../../types/user";
import Loader from "../../Loader";
import Pagination from "../../Pagination";
import { RootState } from "../../../store/store";

type data = {
  data: {
    data: user[];
    per_page: number;
    last_page: number;
  };
};

export default function Users() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<data>(
    `admin/users?page=${searchParams.get("page")}`,
    `users?page=${searchParams.get("page")}`
  );
  const dispatch = useDispatch();
  dispatch(setUser(data?.data?.data!));
  const users = useSelector((state: RootState) => state.users.value);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">المستخدمين</h2>
      <div className="overflow-x-auto">
        <UsersTable users={users} />
        <Pagination pageCount={data?.data?.last_page} />
      </div>
      <Link to="/users/add" className="fixed bottom-8 left-8">
        <AddButton />
      </Link>
    </div>
  );
}
