import { useParams } from "react-router-dom";
import Loader from "../../Loader";
import { order } from "../../../types/order";
import { useFetch } from "../../../ApiS";
import axios from "axios";
import { useEffect, useState } from "react";
import { MapPin } from "lucide-react";

export default function OrderLocation() {
  const { id } = useParams();
  const {
    query: { data, isLoading, isError },
  } = useFetch<{ data: order }>(`admin/orders/${id}`, `orders/${id}`);
  const [address, setAddress] = useState("");

  const getAddress = async () => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${data?.data?.location.latitude}&lon=${data?.data?.location.longitude}&zoom=18&addressdetails=1&accept-language=ar`
      );
      const addressData = response.data;
      setAddress(addressData.display_name || "Unknown");
    } catch (error) {
      console.error(error);
      setAddress("Unknown");
    }
  };

  useEffect(() => {
    getAddress();
  }, [data]);

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12  ">
      <h2 className="font-bold text-2xl">موقع الطلب: {data?.data.id}</h2>
      <div
        className={`flex items-center cursor-pointer w-full gap-4 font-semibold px-1 md:px-4 py-4 md:py-8 rounded bg-white shadow-md rounded transition-colors`}
      >
        <div className="bg-amber-500 rounded-full p-1">
          <MapPin className="text-white" />
        </div>
        {address ? (
          <p className="text-sm">{address}</p>
        ) : (
          <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
        )}
      </div>
    </div>
  );
}
