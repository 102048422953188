import Branch from "./Branch";
import { branch } from "../../../types/branch";

function BranchesTable({ branches }: { branches: branch[] }) {

  return (
    <div className="rounded-sm border border-stroke min-w-[600px] bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col">
        <div className="grid grid-cols-5 rounded-lg bg-amber-50 text-amber-500 ">
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الفرع
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              عدد الموظفين
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الموقع
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              مميز
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              العمليات
            </h5>
          </div>
        </div>
        {branches.length > 0 ? (
          branches?.map((branch, key) => (
            <Branch branch={branch} key={key} length={branches.length} />
          ))
        ) : (
          <div className="mt-12 mb-12">
            <p className="font-bold text-red-600"> لايوجد اي فروع لعرضها</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default BranchesTable;
