import React, { useEffect } from "react";
import { useFetch, usePOST } from "../../../../ApiS";
import { toast } from "sonner";
import Loader from "../../../Loader";

type FormDataType = {
  "contact_us-email"?: string;
  "contact_us-headline-ar"?: string;
  "contact_us-headline-en"?: string;
  "contact_us-phone1"?: string;
  "contact_us-phone2"?: string;
};

interface dataType {
  contact_us: {
    email: string;
    headline: { ar: string; en: string };
    phone1: string;
    phone2: string;
  };
}

export default function ContactUs() {
  const { query: {data, isLoading, isError, error} } = useFetch<dataType>(
    "admin/infos",
    "infos"
  );
  const initialData: FormDataType = {};
  const {
    handleSubmit,
    handleChangeInput,
    formData,
    mutation,
    setFormData
  } = usePOST(
    initialData,
    () => successAction(),
    (error) => errorAction(error)
  );

  useEffect(() => {
    if (mutation.isLoading) toast.loading(`يتم التعديل`, {id: "loading"});
  }, [mutation.isLoading]);

  const successAction = () => {
    toast.success("تم التعديل");
    toast.dismiss("loading");
    setFormData({});
  };

  const errorAction = (error: Error) => {
    toast.error(error.message)
    toast.dismiss("loading");
  }

  const submit = () => {
    handleSubmit("admin/infos/update")
  };

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">تواصل معنا</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <label className="w-full space-y-2">
          <p className="font-semibold">الترويسة بالعربي:</p>
          <input
            defaultValue={data?.contact_us?.headline?.ar}
            name="contact_us-headline-ar"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الترويسة بالعربي"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الترويسة بالانكليزي:</p>
          <input
            defaultValue={data?.contact_us?.headline?.en}
            name="contact_us-headline-ar"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الترويسة بالانكليزي"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الايميل:</p>
          <input
            defaultValue={data?.contact_us?.email}
            name="contact_us-headline-ar"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الايميل"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الرقم الاول:</p>
          <input
            defaultValue={data?.contact_us?.phone1}
            name="contact_us-headline-ar"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الرقم الاول"
            onChange={handleChangeInput}
          />
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الرقم الثاني:</p>
          <input
            defaultValue={data?.contact_us?.phone2}
            name="contact_us-headline-ar"
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="الرقم الثاني"
            onChange={handleChangeInput}
          />
        </label>
      </div>
      {Object.entries(formData).length > 0 && (
        <button
          onClick={submit}
          className="bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
        >
          حفظ
        </button>
      )}
    </div>
  );
}
