import React from "react";
import ChatTable from "./ChatTable";
import { useFetch } from "../../../ApiS";
import Pagination from "../../Pagination";
import { useLocation } from "react-router-dom";
import { chat } from "../../../types/chat";
import { useDispatch } from "react-redux";
import { setChats } from "../../../store/Chats/ChatSlice";
import Loader from "../../Loader";

interface data {
  data: {
    data: chat[];
    per_page: number;
    last_page: number;
  };
}

function Chats() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const {
    query: { data, isLoading, isError, error },
  } = useFetch<any>(
    `admin/chat/users?page=${searchParams.get("page")}`,
    `chats?page=${searchParams.get("page")}`
  );
  const dispatch = useDispatch();
  dispatch(setChats(data?.data?.data));

  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{"خطأ في جلب البيانات"}</p>
  ) : (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">المحادثات</h2>
      <div className="overflow-x-auto max-w-full">
        <ChatTable />
      </div>
      <Pagination pageCount={data?.data?.last_page} />
    </div>
  );
}

export default Chats;
