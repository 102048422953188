import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { RootState } from "../../../store/store";
import ChatInput from "./ChatInput";
import { useFetch } from "../../../ApiS";
import Loader from "../../Loader";
import { chat, message } from "../../../types/chat";
import { setMessages } from "../../../store/Chats/MessagesSlice";
import ListMessages from "./ListMessages";

type MessagesDataType = {
  data: {
    data: message[];
    per_page: number;
    last_page: number;
  };
};

function ChatPreview() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const chatData = useFetch<{ data: chat }>(`admin/admins/${id}`, `admin${id}`);
  const chat = chatData.query.data?.data;
  const {
    query: { data, isLoading, isError, error, refetch, isFetching },
  } = useFetch<MessagesDataType>(
    `admin/chat/users/${id}/messages?page=${page}`,
    `messages${id}`
  );
  const messages = useSelector((state: RootState) => state.messages.value);
  const dispatch = useDispatch();

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data) {
      dispatch(setMessages([...messages, ...data?.data?.data!]));
    }
  }, [data]);

  return isLoading || chatData.query.isLoading ? (
    <Loader />
  ) : isError || chatData.query.isError ? (
    <p>{error?.message || chatData.query.error?.message}</p>
  ) : (
    <div className="text-center flex flex-col w-full h-full gap-8 md:gap-12 relative">
      <h2 className="font-bold text-2xl">
        رسائل {chat?.first_name! + " " + chat?.last_name!}
      </h2>
      <ListMessages
        chat={chat!}
        page={page}
        per_page={data?.data.per_page!}
        setPage={setPage}
        isFetching={isFetching}
        last_page={data?.data.last_page!}
      />
      <ChatInput recieverId={chat?.id!} />
    </div>
  );
}

export default ChatPreview;
