import { createSlice, PayloadAction  } from '@reduxjs/toolkit';
import { chat, message } from '../../types/chat';

export interface ChatState {
  value: chat[]; 
}

const initialState: ChatState = {
  value: [
  ],
};

const chatSlice = createSlice({
  name: 'chatSlice',
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<chat[]>) => {
      state.value = action.payload;
    },
  },
});

export const { setChats } = chatSlice.actions
export default chatSlice.reducer;
