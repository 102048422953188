import React, { useEffect, useRef, useState } from "react";
import AddButton from "../../ui/AddButton";
import { fileURL, usePOST } from "../../../ApiS";
import SliderImage from "./SliderImage";
import { sliderImage } from "./Sliders";
import { toast } from "sonner";
import { AxiosError } from "axios";

export default function ImagesSlider({
  imageslider,
  setImageslider,
  type,
}: {
  imageslider: sliderImage[];
  setImageslider: React.Dispatch<React.SetStateAction<sliderImage[]>>;
  type: string;
}) {
  const inputRef = useRef<any>(null);

  const { handleSubmit, setFormData, formData, mutation } = usePOST(
    {},
    (data) => onSuccess(data?.data?.data),
    (error) => onError(error)
  );

  const onError = (error: AxiosError<{ errors: []; message: string }>) => {
    toast.dismiss("loading");
    toast.error(error.message);
  };

  const onSuccess = (data: sliderImage) =>{
    toast.dismiss("loading");
    toast.success("تمت إضافة الصورة");
    setImageslider(prev => prev ? [...prev, data] : [data])
  }

  useEffect(() => {
    if(mutation.isLoading) 
     toast.loading("يتم الإضافة", {id: "loading"})      
  }, [mutation])

  const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setFormData({ file, type })
    }
  };

  useEffect(() => {
    if(Object.keys(formData).length > 0) 
      handleSubmit("admin/infos/image-slider")
  }, [formData])

  return (
    <div className="text-center h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-2xl">الصور</h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-8">
        {imageslider?.map(
          (image: sliderImage) => (
            <SliderImage
              setImageslider={setImageslider}
              key={image.id}
              image={image}
            />
          )
        )}
      </div>
      <div onClick={() => inputRef.current?.click()}>
        <AddButton />
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleAddImage(e)}
        ref={inputRef}
        className="hidden"
      />
    </div>
  );
}
