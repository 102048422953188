import { createSlice, PayloadAction  } from '@reduxjs/toolkit';
import { chat, message } from '../../types/chat';
import { contactMessage } from '../../types/contactMessage';

export interface ChatState {
  value: contactMessage[]; 
}

const initialState: ChatState = {
  value: [
  ],
};

const contactSlice = createSlice({
  name: 'chatSlice',
  initialState,
  reducers: {
    removeMessage: (state, action: PayloadAction<contactMessage>) => {
      state.value = state.value.filter(
        (message) => message.id !== action.payload.id
      );
    },
    setMessages: (state, action: PayloadAction<contactMessage[]>) => {
      state.value = action.payload;
    },
  },
});

export const { removeMessage, setMessages } = contactSlice.actions;
export default contactSlice.reducer;
