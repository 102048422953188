import { Link } from "react-router-dom";
import { fileURL, useFetch } from "../../../ApiS";
import { chat } from "../../../types/chat";
import Loader from "../../Loader";
import UserAvatar from "../../../images/UserAvatar.jpg"

const ChatCard = () => {
  const {
    query: { data, isLoading },
  } = useFetch<{ data: { data: chat[] } }>(
    "admin/chat/users/last",
    "lastMessages"
  );
  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white py-6 shadow-default dark:border-strokedark dark:bg-boxdark xl:col-span-4">
      <h4 className="mb-6 px-7.5 text-xl font-semibold text-black dark:text-white">
        اخر الرسائل
      </h4>

      <div>
        {isLoading ? (
          <Loader />
        ) : (
          data?.data?.data?.map((chat, key) => (
            <Link
              to={`/chats/reply/${chat.id}/?page=1`}
              className="flex items-center gap-5 py-3 px-7.5 hover:bg-gray-2 transition-colors"
              key={key}
            >
              <div className="relative rounded-full h-14 w-14 rounded-full">
                <img src={chat.image ? fileURL + chat.image : UserAvatar} alt="User" />
              </div>

              <div className="flex flex-1 items-center justify-between">
                <div className="flex flex-col">
                  <h5 className="font-medium text-black dark:text-white">
                    {chat.username}
                  </h5>
                  <p>{chat.last_message_content}</p>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default ChatCard;
