import Order from "./Order";
import { order } from "../../../types/order";

export default function OrdersTable({ orders }: { orders: order[] }) {
  return (
    <div className="rounded-sm border border-stroke min-w-[700px] bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default dark:border-strokedark dark:bg-boxdark ">
      <div className="flex flex-col">
        <div className="grid grid-cols-7 rounded-sm bg-amber-50 text-amber-500 ">
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الرقم
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الحالة
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              السعر
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              المستخدم
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              المنتجات
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الموقع
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              العمليات
            </h5>
          </div>
        </div>

        {orders?.length > 0 ? (
          orders?.map((order, key) => (
            <Order order={order} key={key} length={orders.length} />
          ))
        ) : (
          <div className="mt-12 mb-12">
            <p className="font-bold text-red-600"> لايوجد اي طلبات لعرضها</p>
          </div>
        )}
      </div>
    </div>
  );
}
