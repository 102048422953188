import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { brand } from "../../types/brand";

export interface SectionState {
  value: brand[];
}

const initialState: SectionState = {
  value: [],
};

const brandSlice = createSlice({
  name: "brandSlice",
  initialState,
  reducers: {
    addBrand: (state, action: PayloadAction<brand>) => {
      state.value = [...state.value, action.payload];
    },
    removeBrand: (state, action: PayloadAction<brand>) => {
      state.value = state.value.filter(
        (section) => section.id !== action.payload.id
      );
    },
    setBrands: (state, action: PayloadAction<brand[]>) => {
      state.value = action.payload;
    },
    editBrand: (state, action: PayloadAction<brand>) => {
      state.value = state.value.map((brand) => {
        if (brand.id === action.payload.id) {
          return action.payload;
        }
        return brand;
      });
    },
  },
});

export const { addBrand, removeBrand, setBrands, editBrand } =
brandSlice.actions;
export default brandSlice.reducer;
