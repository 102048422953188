import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../store/store";
import JoditEditor from "jodit-react";
import EditButton from "../../ui/EditButton";
import { toast } from "sonner";
import { addOffer, editOffer } from "../../../store/Offers/OffersSlice";
import { offer } from "../../../types/offer";
import { fileURL, useFetch, usePOST } from "../../../ApiS";
import AddImage from "../../AddImage";
import { AxiosError } from "axios";
import Loader from "../../Loader";

export type FormDataType = {
  id?: number;
  image?: File;
  ar_name?: string;
  en_name?: string;
  ar_description?: string;
  en_description?: string;
  _method?: string;
  discount?: string;
};

type Errors = {
  image?: string[];
  ar_name?: string[];
  en_name?: string[];
  ar_description?: string[];
  en_description?: string[];
  discount?: string[];
};

function AddEditOffers() {
  const { id } = useParams();
  let enabled = id ? true : false
  const {query: {isLoading, isError, error, data}} = useFetch<{data: offer}>(`admin/offers/${id}`, `offer${id}`, enabled);
  const offer = data?.data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialData: FormDataType = {
    _method: id ? "put" : "post",
  };
  const [errors, setErrors] = useState<Errors>({});
  const {
    setFormData,
    handleSubmit,
    handleChangeInput,
    viewfile,
    formData,
    mutation,
  } = usePOST(
    initialData,
    (data) => onSuccess(data?.data?.data!),
    (error) => onError(error)
  );

  const onError = (error: AxiosError<{ errors: Errors; message: string }>) => {
    toast.dismiss("loading");
    toast.error(error.message);
    setErrors(error.response?.data.errors || {});
  };

  const onSuccess = (offer: offer) => {
    navigate(-1);
    toast.dismiss("loading");
    if (id) {
      toast.success("تم التعديل بنجاح");
      dispatch(editOffer(offer));
    } else {
      toast.success("تم الإضافة بنجاح");
      dispatch(addOffer(offer));
    }
    setErrors({})
  };

  useEffect(() => {
    setFormData(initialData);
  }, [initialData.ar_name]);

  useEffect(() => {
    if (mutation.isLoading)
      toast.loading(`يتم ${id ? "التعديل" : "الإضافة"}`, { id: "loading" });
  }, [mutation]);

  const addAction = () => {
    handleSubmit(`admin/offers`);
  };

  const editAction = () => {
    handleSubmit(`admin/offers/${id}`);
  };

  const validate = () => {
    const keysToCheck = Object.keys(formData).filter(
      (key) => key !== "_method"
    ) as (keyof FormDataType)[];

    let isValid;

    if (keysToCheck.length > 0) {
      isValid = id
        ? keysToCheck.some((key) => !!formData[key])
        : keysToCheck.length === 6;
    } else isValid = false;

    return isValid;
  };

  const submit = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!id) {
      if (validate()) addAction();
      else toast.error("قم بالإضافة");
    } else {
      if (validate()) {
        editAction();
      } else toast.error("قم بالتعديل");
    }
  };
  return isLoading ? (
    <Loader />
  ) : isError ? (
    <p>{error?.message}</p>
  ) : (
    <div className="h-full relative space-y-8 md:space-y-12">
      <h2 className="font-bold text-center text-2xl">
        {offer ? "تعديل العرض: " + offer?.ar_name : "إضافة عرض"}
      </h2>

      <form className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
        <label className="col-span-1 sm:col-span-2">
          <AddImage
            inputName="image"
            initialImage={offer ? fileURL + offer?.image : undefined}
            viewImage={viewfile}
            handleChangeInput={handleChangeInput}
          />
          {errors.image &&
            errors.image.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الاسم بالعربي:</p>
          <input
            defaultValue={offer?.ar_name}
            type="text"
            name="ar_name"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="اكتب الاسم"
            onChange={handleChangeInput}
          />
          {errors.ar_name &&
            errors.ar_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold">الاسم بالانكليزي:</p>
          <input
            defaultValue={offer?.en_name}
            name="en_name"
            onChange={handleChangeInput}
            type="text"
            className="p-2 w-full border border-slate-300 rounded-md"
            dir="ltr"
            placeholder="Type Name"
          />
          {errors.en_name &&
            errors.en_name.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="w-full space-y-2">
          <p className="font-semibold"> مقدار الخصم:</p>
          <input
            value={offer?.discount}
            name="discount"
            type="number"
            className="p-2 w-full border border-slate-300 rounded-md"
            placeholder="اكتب مقدار الخصم"
            onChange={handleChangeInput}
          />
          {errors.discount &&
            errors.discount.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <label className="w-full space-y-2 col-span-1 sm:col-span-2">
          <p className="font-semibold">الوصف بالعربي:</p>
          <JoditEditor
            onChange={(value) =>
              setFormData((prev) => ({ ...prev, ar_description: value }))
            }
            value={offer?.ar_description!}
          />
          {errors.ar_description &&
            errors.ar_description.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>
        <label className="w-full space-y-2 col-span-1 sm:col-span-2">
          <p className="font-semibold">الوصف بالانكليزي:</p>
          <JoditEditor
            onChange={(value) =>
              setFormData((prev) => ({ ...prev, en_description: value }))
            }
            value={offer?.en_description!}
          />
          {errors.en_description &&
            errors.en_description.map((error, index) => (
              <p key={index} className="text-red-600 text-sm" dir="auto">
                {error}
              </p>
            ))}
        </label>

        <input
          type="submit"
          onClick={(e) => submit(e)}
          value={id ? "تعديل" : "حفظ"}
          className="col-span-1 sm:col-span-2 bg-amber-500 cursor-pointer w-1/2 mx-auto p-2 rounded text-white border border-amber-500 hover:bg-white hover:text-amber-500 transition-colors"
        />
      </form>
    </div>
  );
}

export default AddEditOffers;
