import React, { Key, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import DeleteDialog from "../../DeleteDialog";
import EditButton from "../../ui/EditButton";
import { useDispatch } from "react-redux";
import { removeBranch } from "../../../store/Branches/BranchesSlice";
import { removeMainBranch } from "../../../store/Branches/MainBranchesSlice";
import { fileURL, useDelete } from "../../../ApiS";
import { admin } from "../../../types/admin";
import { toast } from "sonner";
import { removeAdmin } from "../../../store/Admins/AdminsSlice";

function Admin({
  admin,
  key,
  length,
}: {
  admin: admin;
  key: Key;
  length: number;
}) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { deleteItem, mutation } = useDelete(
    () => onDelete(),
    () => {toast.error("Error while deleting."); toast.dismiss("loading")}
  );

  useEffect(() => {
    if (mutation.isLoading) toast.loading("يتم الحذف", { id: "loading" });
  }, [mutation]);

  const onDelete = () => {
    toast.dismiss();
    dispatch(removeAdmin(admin));
  };

  const deleteAction = () => {
    deleteItem(`admin/admins/${admin.id}`);
  };

  return (
    <div
      className={`grid grid-cols-4 ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark"
      }`}
      key={key}
    >
      <div className="flex items-center gap-3 p-2.5 xl:p-5">
        {admin.image ? (
          <div className="flex-shrink-0">
            <img
              src={`${fileURL}${admin.image}`}
              alt="Brand"
              className="w-20 rounded"
            />
          </div>
        ) : null}
        <p className="text-black dark:text-white md:block">{admin?.username}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{admin?.email}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{admin?.phone_number}</p>
      </div>

      <div className="flex flex-wrap gap-3 items-center justify-center p-2.5 xl:p-5">
        {pathname.includes("/admins") && (
          <Link to={`/admins/edit/${admin?.id}`}>
            <EditButton />
          </Link>
        )}
        <DeleteDialog action={deleteAction} title={"هل تريد حذف المدير"} />
      </div>
    </div>
  );
}

export default Admin;
