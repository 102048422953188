
import User from "./User";
import { user } from "../../../types/user";

function UsersTable({users} : {users: user[]}) {

  return (
    <div className="rounded-sm border border-stroke min-w-[600px] bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col">
        <div className="grid grid-cols-5 rounded-sm bg-amber-50 text-amber-500 ">
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">رقم</h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
             المستتخدم
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الايميل
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              رقم الهاتف
            </h5>
          </div>

          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              العمليات
            </h5>
          </div>
        </div>

        {users?.length > 0 ? (
          users?.map((user, key) => (
            <User user={user} key={key} length={users.length} />
          ))
        ) : (
          <div className="mt-12 mb-12">
            <p className="font-bold text-red-600"> لايوجد اي مستخدمين </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default UsersTable;
