import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { offer } from "../../types/offer";
import SectionImage from "../../images/section.png";
import BrandImage from "../../images/brand.png";

export interface OfferState {
  value: offer[];
}
const initialState: OfferState = {
  value: [],
};
const OffersSlice = createSlice({
  name: "OffersSlice",
  initialState,
  reducers: {
    addOffer: (state, action: PayloadAction<offer>) => {
      state.value = [...state.value, action.payload];
    },
    removeOffer: (state, action: PayloadAction<offer>) => {
      state.value = state.value.filter(
        (offer) => offer.id !== action.payload.id
      );
    },
    setOffer: (state, action: PayloadAction<offer[]>) => {
      state.value = action.payload;
    },
    editOffer: (state, action: PayloadAction<offer>) => {
      state.value = state.value.map((offer) => {
        if (offer.id === action.payload.id) {
          return action.payload;
        }
        return offer;
      });
    },
  },
});

export const { addOffer, removeOffer, setOffer, editOffer } =
  OffersSlice.actions;
export default OffersSlice.reducer;
