import Section from "./Section";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useLocation } from "react-router-dom";
import { section } from "../../../types/section";

function SectionsTable({ sections }: { sections: section[] }) {
  const { pathname } = useLocation();

  return (
    <div className="rounded-sm border border-stroke min-w-[600px] bg-white px-2 sm:px-7.5 pt-6 pb-2.5 xl:pb-1 shadow-default dark:border-strokedark dark:bg-boxdark">
      <div className="flex flex-col">
        <div
          className={`grid grid-cols-4 rounded-sm bg-amber-50 text-amber-500`}
        >
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              القسم
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              مميز
            </h5>
          </div>
          <div className={`p-2.5 text-center sm:block xl:p-5`}>
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              الماركات
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              العمليات
            </h5>
          </div>
        </div>

        {sections?.length > 0 ? (
          sections?.map((section, key) => (
            <Section section={section} key={key} length={sections.length} />
          ))
        ) : (
          <div className="mt-12 mb-12">
            <p className="font-bold text-red-600"> لايوجد اي أقسام لعرضها</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SectionsTable;
