import React, { Key } from "react";
import { employee } from "../../../types/branch";

import DeleteDialog from "../../DeleteDialog";
import { fileURL } from "../../../ApiS";
import DialogAddEmployee from "./DialogAddEmployee";

export default function Employee({
  employee,
  key,
  length,
  setEmployees,
}: {
  employee: employee;
  key: number;
  length: number;
  setEmployees: React.Dispatch<React.SetStateAction<employee[] | undefined>>;
}) {
  const deleteAction = () => {
    if (employee.uploaded)
      setEmployees((prev) => prev?.filter((em, index) => index !== key));
    else
      setEmployees((prev) =>
        prev?.map((em) => {
          if (em.id === employee.id) return { ...em, deleted: true };
          return em;
        })
      );
  };

  return employee.deleted ? null : (
    <div
      className={`grid grid-cols-6 ${
        key === length - 1
          ? ""
          : "border-b border-stroke dark:border-strokedark"
      }`}
      key={key}
    >
      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <img
          src={
            employee.uploaded
              ? URL.createObjectURL(employee?.image as File)
              : fileURL + employee?.image
          }
          alt="Brand"
          className="w-20 rounded"
        />
      </div>
      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white md:block">
          {employee?.ar_name}
        </p>
      </div>
      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white md:block">
          {employee?.en_name}
        </p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{employee?.ar_job}</p>
      </div>

      <div className="flex items-center justify-center p-2.5 xl:p-5">
        <p className="text-black dark:text-white">{employee?.en_job}</p>
      </div>

      <div className="flex flex-wrap gap-3 items-center justify-center p-2.5 xl:p-5">
        <DeleteDialog action={deleteAction} title={"هل تريد حذف الموظف"} />
      </div>
    </div>
  );
}
