import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import SectionImage from "../../images/section.png";
import { product } from "../../types/products";

export interface ProductState {
  value: product[];
}
const initialState: ProductState = {
  value: [],
};
const ProductsSlice = createSlice({
  name: "ProductsSlice",
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<product>) => {
      state.value = [...state.value, action.payload];
    },
    removeProduct: (state, action: PayloadAction<product>) => {
      state.value = state.value.filter(
        (product) => product.id !== action.payload.id
      );
    },
    setProduct: (state, action: PayloadAction<product[]>) => {
      state.value = action.payload;
    },
    editProduct: (state, action: PayloadAction<product>) => {
      state.value = state.value.map((product) => {
        if (product.id === action.payload.id) {
          return action.payload;
        }
        return product;
      });
    },
  },
});

export const { addProduct, removeProduct, setProduct, editProduct } =
  ProductsSlice.actions;
export default ProductsSlice.reducer;
