import React, { Key, useEffect } from 'react'
import { contactMessage } from '../../../types/contactMessage';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useDelete, usePOST } from '../../../ApiS';
import { toast } from 'sonner';
import { removeMessage } from '../../../store/ContactMesssage/ContactSlice';
import EditButton from '../../ui/EditButton';
import DeleteDialog from '../../DeleteDialog';
import { useQueryClient } from 'react-query';

export default function ContactMessage({
    message,
    length,
    key
  }: {
    message: contactMessage;
    key: number
    length: number;
  }) {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const { deleteItem, mutation: {isLoading} } = useDelete(
      () => onDelete(),
      () => toast.error("Error while deleting.")
    );
  
    const onDelete = () => {
      toast.dismiss("loading");
      toast.success("تمت الإزالة");
      dispatch(removeMessage(message));
    }
  
    useEffect(() => {
      if (isLoading)
        toast.loading(`يتم الإزالة`, { id: "loading" });
    }, [ isLoading]);
  
    const deleteAction = () => {
        deleteItem(`admin/contact-messages/${message.id}`);
    };
  
    return (
      <div
        className={`grid grid-cols-5  ${
          key === length - 1
            ? ""
            : "border-b border-stroke dark:border-strokedark  "
        }`}
        key={key}
      >
        <div className="flex items-center justify-center p-2.5 xl:p-5">
          <p className="text-black dark:text-white md:block">{message.id}</p>
        </div>
  
        <div className="flex items-center justify-center p-2.5 xl:p-5">
          <p className="text-black dark:text-white truncate">
            {message.name}
          </p>
        </div>
        
        <div className="flex items-center justify-center p-2.5 xl:p-5">
          <p className="text-black dark:text-white truncate">
            {message.message}
          </p>
        </div>
  
        <div className="flex items-center justify-center p-2.5 xl:p-5">
          <p className="text-black dark:text-white">
            {message.email}
          </p>
        </div>
  
        <div className="flex flex-wrap items-center justify-center gap-3 p-2.5 xl:p-5">
          <DeleteDialog action={deleteAction} title={"هل تريد حذف المنتج"} />
        </div>
      </div>
    );
  }
